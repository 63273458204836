import { React } from "react";
import "./notfound.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Grid, Preloader, Hero } from "../../components";
import { CompanyName } from "../../data";
import {
  NotfoundpageHero,
  NotfoundpageMeta,
} from "../../data/DataNotfoundpage";

const CompanyNameTitle = CompanyName;

function NotFound() {
  const hero = NotfoundpageHero;
  const meta = NotfoundpageMeta;

  return (
    <div className="content">
      <HelmetProvider>
          <Grid
            grid={{
              className: "page",
              pageName: "legal-page",
              isFull: true,
            }}
          >
            <Helmet>
              <html lang="de" />
              <title>{CompanyNameTitle} - Seite nicht gefunden</title>
              <meta name="description" content={meta} />
            </Helmet>
            <Hero hero={hero} />
          </Grid>
          <Preloader />
        </HelmetProvider>
    </div>
  );
}

export default NotFound;
