import React from "react";
import { useNavigate } from "react-router-dom";
import List from "../list/List";
import "./booking.css";

const ServiceSelection = ({ data, setData }) => {
  const navigate = useNavigate();

  const handleSelectItems = (items) => {
    if (items.length > 0) {
      setData(prev => ({ ...prev, service: items }));
      console.log("🔀 Navigating to /calendar with selected services:", items);
      navigate("/termin/calendar");
    } else {
      console.warn("⚠️ No services selected. Not navigating.");
    }
  };

  return (
    <div className="booking-step-container">
      <List 
        isBookingForm={true} 
        onSelectItems={handleSelectItems} 
        serviceId={data.service.length > 0 ? data.service[0].serviceId : null} 
      />
    </div>
  );
};

export default ServiceSelection;