import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { auth, db } from "../../firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import "./booking.css";

const UserDataForm = ({ data, setData }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [userDetails, setUserDetails] = useState(data.user || { firstName: "", lastName: "", email: "", phone: "" });
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // ✅ Fetch user details if logged in
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setIsAuthenticated(true);
        const userRef = doc(db, "users", user.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          const userData = userSnap.data();
          setUserDetails((prev) => ({
            ...prev,
            firstName: userData.firstName || prev.firstName,
            lastName: userData.lastName || prev.lastName,
            email: userData.email || prev.email,
            phone: userData.phone || prev.phone,
          }));
        }
      } else {
        setIsAuthenticated(false);
      }
    });

    return () => unsubscribe();
  }, []);

  // ✅ Handle user input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prev) => ({ ...prev, [name]: value }));
  };

  // ✅ Proceed to confirmation
  const handleSubmit = () => {
    setData((prev) => ({ ...prev, user: userDetails }));
    navigate("/termin/confirmation");
  };

  // ✅ Redirect to login with return URL
  const handleLoginRedirect = () => {
    navigate(`/login?returnUrl=${location.pathname}`);
  };

  return (
    <motion.div className="booking-step-container user-details-form">
      <h2 className="step-title">Deine Daten</h2>

      {!isAuthenticated && (
        <div className="login-prompt">
          <p>Melde dich an, um deine Daten automatisch auszufüllen.</p>
          <button className="btn-login btn-outline" onClick={handleLoginRedirect}>
            Anmelden
          </button>
        </div>
      )}

      <input type="text" name="firstName" placeholder="Vorname" value={userDetails.firstName} onChange={handleInputChange} required />
      <input type="text" name="lastName" placeholder="Nachname" value={userDetails.lastName} onChange={handleInputChange} required />
      <input type="email" name="email" placeholder="Mail Adresse" value={userDetails.email} onChange={handleInputChange} required />
      <input type="tel" name="phone" placeholder="Telefonnummer" value={userDetails.phone} onChange={handleInputChange} required />

      <button onClick={handleSubmit} className="btn-confirm btn-highlight" disabled={!userDetails.firstName || !userDetails.lastName || !userDetails.email || !userDetails.phone}>
        Weiter zur Bestätigung
      </button>
    </motion.div>
  );
};

export default UserDataForm;
