import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./booking.css";

const ProgressBar = ({ currentStep }) => {
  const navigate = useNavigate();
  const [blockedStep, setBlockedStep] = useState(null);

  const steps = [
    { step: "/termin", icon: "fas fa-sitemap" },
    { step: "/termin/calendar", icon: "fas fa-calendar" },
    { step: "/termin/timeslot", icon: "fas fa-clock" },
    { step: "/termin/userdata", icon: "fas fa-user" },
    { step: "/termin/confirmation", icon: "fas fa-list" },
    { step: "/termin/success", icon: "fas fa-check" }
  ];

  const calculateProgressWidth = () => {
    const progressMap = {
      "/termin": (100 / steps.length) * 0.5,
      "/termin/calendar": (100 / steps.length) * 1.5,
      "/termin/timeslot": (100 / steps.length) * 2.5,
      "/termin/userdata": (100 / steps.length) * 3.5,
      "/termin/confirmation": (100 / steps.length) * 4.5,
      "/termin/success": (100 / steps.length) * 6
    };
    return progressMap[currentStep] || 0;
  };

  const handleNavigation = (stepIndex, step) => {
    const currentStepIndex = steps.findIndex(s => s.step === currentStep);
    const isPrevious = stepIndex < currentStepIndex;
    const lastStep = steps[steps.length - 1].step;

    if (currentStep === lastStep && isPrevious) {
      setBlockedStep(step); // Trigger shake animation on forbidden click
      setTimeout(() => setBlockedStep(null), 500); // Remove class after animation
      return;
    }

    setBlockedStep(null); // Reset if allowed
    if (isPrevious) navigate(step);
  };

  return (
    <div className="bookings-steps">
      {steps.map(({ step, icon }, index) => {
        const currentStepIndex = steps.findIndex(s => s.step === currentStep);
        const isPrevious = index < currentStepIndex;
        const isBlocked = blockedStep === step;

        return (
          <div
            key={step}
            className={`step-indicator ${currentStep === step ? "active" : ""} ${isPrevious ? "previous" : ""}`}
            onClick={() => handleNavigation(index, step)}
          >
            <div className={`step-circle ${isBlocked ? "shake" : ""}`}>
              <i className={icon}></i>
            </div>
          </div>
        );
      })}
      <div className="step-line">
        <div className="progress-line" style={{ width: `${calculateProgressWidth()}%` }}></div>
      </div>
    </div>
  );
};

export default ProgressBar;
