import React, { useState, useEffect } from "react";
import apiRequest from "../../auth/auth";
import "./booking.css";

const Calendar = ({ serviceId, onSelectDate }) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [timeSlots, setTimeSlots] = useState([]);
  const [dateClassMap, setDateClassMap] = useState({});

  useEffect(() => {
  const fetchTimeSlots = async () => {
    if (!serviceId) {
      console.error("❌ Error: serviceId is undefined!");
      return;
    }

    const year = currentMonth.getFullYear();
    const month = String(currentMonth.getMonth() + 1).padStart(2, "0");
    const url = `https://www.etermin.net/api/timeslots?date=${year}-${month}-01&serviceid=${serviceId}&rangesearch=1&caching=false&capacity=1&duration=0&cluster=false&slottype=0&fillcalendarstrategy=0&showavcap=false&appfuture=180&appdeadline=60&appdeadlinewm=0&oneoff=null&msdcm=0&calendarid=`;

    try {
      const response = await apiRequest(url, "GET");
      setTimeSlots(response);
    } catch (error) {
      console.error("Error fetching timeslots:", error);
    }
  };

  fetchTimeSlots();
}, [currentMonth, serviceId]); // ✅ serviceId must be defined

useEffect(() => {
    const updateDateClassMap = () => {
      let newDateClassMap = {};

      timeSlots.forEach((slot) => {
        const date = slot.start.split("T")[0];
        const dayOfWeek = new Date(date).getDay(); // 🔥 Get day of week (0=Sunday, 1=Monday)

        if (dayOfWeek === 0 || dayOfWeek === 1) {
          newDateClassMap[date] = "disabled"; // 🔥 Mark Sundays & Mondays as disabled
        } else if (slot.available) {
          newDateClassMap[date] = "available";
        } else if (slot.wl) {
          newDateClassMap[date] = "waitlist";
        } else {
          newDateClassMap[date] = "unavailable";
        }
      });

      setDateClassMap(newDateClassMap);
    };

    updateDateClassMap();
}, [timeSlots]);

const handleDateSelection = (selectedDate) => {
  const status = dateClassMap[selectedDate];

  if (status === "waitlist") {
    // 🔥 Find start & end time for this waitlist day
    const selectedSlot = timeSlots.find((slot) => slot.start.startsWith(selectedDate));

    if (selectedSlot) {
      const waitlistStartTime = selectedSlot.wlstart;
      const waitlistEndTime = selectedSlot.wlend;

      console.log(`⏳ Waitlist selected: ${selectedDate} (${waitlistStartTime} - ${waitlistEndTime})`);

      // ✅ Pass the correct times along with the selected date
      onSelectDate(selectedDate, waitlistStartTime, waitlistEndTime);
      return;
    }
  }

  // ✅ Normal date selection
  onSelectDate(selectedDate, null, null);
};


const renderDays = () => {
  const year = currentMonth.getFullYear();
  const month = currentMonth.getMonth();
  const daysInMonth = new Date(year, month + 1, 0).getDate();
  
  let firstDayOfMonth = new Date(year, month, 1).getDay();
  let adjustedFirstDay = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1;

  console.log(`📅 First day of ${year}-${month + 1}-01 starts at index: ${adjustedFirstDay} (Monday=0)`);

  const today = new Date();
  const todayFormatted = today.toISOString().split("T")[0]; // ✅ "YYYY-MM-DD"

  const grid = [];

  for (let i = 0; i < adjustedFirstDay; i++) {
      grid.push(<div key={`empty-${i}`} className="day empty"></div>);
  }

  for (let day = 1; day <= daysInMonth; day++) {
      const formattedDate = `${year}-${String(month + 1).padStart(2, "0")}-${String(day).padStart(2, "0")}`;
      const status = dateClassMap[formattedDate] || "unavailable";
    
      const dateObj = new Date(year, month, day);
      const dayOfWeek = dateObj.getDay(); // ✅ Sunday (0), Monday (1)

      const isPast = formattedDate < todayFormatted; // 🔥 Check if date is in the past
      const isDisabled = isPast || dayOfWeek === 0 || dayOfWeek === 1; // 🔥 Block past days & Sun/Mon
      const clickHandler = isDisabled ? undefined : () => handleDateSelection(formattedDate);

      grid.push(
        <div key={formattedDate} 
             className={`day ${status} ${isDisabled ? "disabled past" : ""}`} 
             onClick={clickHandler}>
          {day}
        </div>
      );
  }

  const remainingSlots = grid.length % 7 === 0 ? 0 : 7 - (grid.length % 7);
  for (let i = 0; i < remainingSlots; i++) {
      grid.push(<div key={`empty-end-${i}`} className="day empty"></div>);
  }

  return grid;
};


  return (
    <div className="custom-calendar">
      <div className="calendar-header">
        <button onClick={() => setCurrentMonth((prev) => new Date(prev.getFullYear(), prev.getMonth() - 1, 1))}>
          <i className="fas fa-chevron-left"></i>
        </button>
        <span>{currentMonth.toLocaleString("de-DE", { month: "long", year: "numeric" })}</span>
        <button onClick={() => setCurrentMonth((prev) => new Date(prev.getFullYear(), prev.getMonth() + 1, 1))}>
          <i className="fas fa-chevron-right"></i>
        </button>
      </div>

      <div className="calendar-days-header">
        {["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"].map((day, index) => (
          <div key={index} className="day-header">
            {day}
          </div>
        ))}
      </div>

      <div className="calendar-grid">{renderDays()}</div>
    </div>
  );
};

export default Calendar;
