import React, { useState, useEffect, useRef } from "react";
import { useInView } from "framer-motion";
import "./footer.css";
import { CompanyName } from "../../data";
import { NavLink } from "react-router-dom";
import newYear from "../../data/CurrentYear";

const logoDark = require("../../images/XS-Logo-Black.png");
const logoLight = require("../../images/XS-Logo-White.png");

const CompanyNameTitle = CompanyName;

const Footer = () => {
  const [logo, setLogo] = useState(logoDark);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  function handleLogo() {
    if (localStorage.getItem("theme") === "dark") {
      setLogo(logoLight);
    } else setLogo(logoDark);
  }

  useEffect(() => {
    window.addEventListener("storage", handleLogo);
    handleLogo();

    return () => {
      window.addEventListener("storage", handleLogo);
    };
  });

  return (
    <footer id="footer" ref={ref}>
      <div
        style={{
          transform: isInView ? "none" : "translateY(100px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
        }}
        className={["footer"].join(" ")}
      >
        <div className="footer-first-container">
          <div className="footer-logo_container">
            <NavLink to="/">
              <img className="footer-logo" src={logo} alt="Logo" />
            </NavLink>
          </div>
          <div className="footer-box">
            <h5 className="footer-overview">Seitenübersicht</h5>
            <NavLink to="/home">Home</NavLink>
            <NavLink to="/termin">Termin</NavLink>
            <NavLink to="/about">Über uns</NavLink>
            <NavLink to="/services">Leistungen</NavLink>
            <NavLink to="/projects">Galerie</NavLink>
            <NavLink to="/contact">Kontakt</NavLink>
            <NavLink to="/legal">Rechtliches</NavLink>
          </div>
          <div className="footer-box">
            <h5>Über XS-Barbershop</h5>
            <p className="footer-text">
              XS-Barbershop – deine Anlaufstelle für exzellente Haarschnitte und
              Bartfrisuren im Rhein-Main Gebiet! Wir freuen uns auf deinen
              Besuch in unserem Shop in Langen mit Parkplätzen direkt vor der
              Tür.
            </p>
          </div>
          <div className="footer-box">
            <h5>Kontakt</h5>
            <p className="footer-text">
              Hast du Fragen oder benötigst weitere Informationen? Kontaktiere
              uns gerne per E-Mail unter{" "}
              <a className="text-bright" href="mailto:info@xs-barbershop.com">
                info@xs-barbershop.com
              </a>{" "}
              oder telefonisch unter{" "}
              <a className="text-bright" href="tel:061034877822">
                06103 4877822
              </a>
              . Wir stehen jederzeit zur Verfügung.
            </p>
          </div>
        </div>
        <div className="footer-second-container">
          <div className="footer-box full">
            <p className="footer-text">
              Copyright &copy; {CompanyNameTitle} {newYear} - Umsetzung: Joshua
              Krieg
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
