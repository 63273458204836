import React, { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { TbRazor } from "react-icons/tb";
import { RiScissorsLine } from "react-icons/ri";
import { FaMask, FaStar } from "react-icons/fa";
import "./list.css";
import { listItems } from "../../data/DataDefault";

function List({ isBookingForm, onSelectItems, serviceId }) {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const items = listItems;

  useEffect(() => {
    console.log("🔍 List.jsx Loaded - Props:", { serviceId, items, isBookingForm });

    if (!serviceId || serviceId.length === 0) return;

    const selectedServiceId = Array.isArray(serviceId) ? serviceId[0] : serviceId;
    const filteredItem = items.find(item => item.serviceId === selectedServiceId && item.bookable);

    if (filteredItem) {
      console.log("✅ Pre-selected service:", filteredItem);
      setSelectedItems([filteredItem]); // Pre-select but don't confirm
    }
  }, [serviceId, items, isBookingForm]);

  const handleItemClick = (item) => {
    setSelectedItems((prevSelected) => {
      const updatedSelection = prevSelected.includes(item)
        ? prevSelected.filter(i => i !== item)
        : [...prevSelected, item];

      console.log("🖱 Clicked Item:", item);
      console.log("📌 Updated Selected Items:", updatedSelection);
      return updatedSelection;
    });
  };

  const handleConfirmSelection = () => {
    console.log("✔ Button Clicked - Confirming Selection:", selectedItems);
    onSelectItems(selectedItems);
  };

  const categories = [...new Set(items.map(item => item.category))];

  const filteredItems = items
    .filter(item =>
      (selectedCategories.length === 0 || selectedCategories.includes(item.category)) &&
      (searchText.length < 3 || item.name.toLowerCase().includes(searchText.toLowerCase()))
    )
    .sort((a, b) => a.name.localeCompare(b.name));

  const getCategoryIcon = (category) => {
    switch (category) {
      case "VIP": return <FaStar />;
      case "Haare": return <RiScissorsLine />;
      case "Bart": return <TbRazor />;
      case "Kosmetik": return <FaMask />;
      default: return null;
    }
  };

  return (
    <div className="list-wrapper">
      {isBookingForm && <h3>Leistung auswählen</h3>}
      <div className="list-container">
        <input
          className="list-search-input"
          type="text"
          placeholder="Leistung suchen"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <div className="filter-buttons-wrapper">
          <div className="filter-buttons-container">
            {categories.map(category => (
              <button key={category} onClick={() => setSelectedCategories([category])}
                className={selectedCategories.includes(category) ? "btn-dark" : "btn-outline"}>
                {category}
              </button>
            ))}
            <button onClick={() => setSelectedCategories([])} className="btn-outline">Zeige Alle</button>
          </div>
        </div>

        {filteredItems.length === 0 ? (
          <p><i className="fas fa-exclamation-triangle" /> Keine Ergebnisse gefunden.</p>
        ) : (
          <motion.ul className="list-items">
            <AnimatePresence>
              {filteredItems.map(item => (
                <motion.li key={item.id} onClick={() => handleItemClick(item)}
                  className={`list-item ${!item.bookable ? "non-bookable" : ""} ${selectedItems.includes(item) ? "selected" : ""}`}>
                  <span className="list-item-details">
                    <span className="list-item-category">{getCategoryIcon(item.category)}</span>
                    <span className="list-item-title">{item.name}</span>
                  </span>
                  <span className="list-item-price">{item.price}</span>
                </motion.li>
              ))}
            </AnimatePresence>
          </motion.ul>
        )}

        {isBookingForm && (
          <button onClick={handleConfirmSelection}
            className="btn-highlight" disabled={selectedItems.length === 0}>
            Auswahl bestätigen
          </button>
        )}
      </div>
    </div>
  );
}

export default List;
