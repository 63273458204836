import React, { useState, useEffect, useMemo } from "react";
import { motion } from "framer-motion";
import { auth, db } from "../../firebaseConfig"; // ✅ Firebase Config
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  onAuthStateChanged,
  sendEmailVerification,
} from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore"; // ✅ Firestore functions
import { NavLink, useNavigate, useLocation } from "react-router-dom"; // ✅ useLocation for returnUrl
import "./login.css"; // ✅ Ensure styles are applied

const LoginComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePassword = () => setShowPassword(!showPassword);
  const toggleConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  // ✅ Get returnUrl from query params or default to "/"
  const returnUrl =
    new URLSearchParams(location.search).get("returnUrl") || "/";

  const initialFormState = useMemo(
    () => ({
      email: "",
      password: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
      phone: "",
    }),
    []
  );

  const [formData, setFormData] = useState(initialFormState);
  const [mode, setMode] = useState("login");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userName, setUserName] = useState("");

  // ✅ Track Authentication State
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setIsAuthenticated(true);

        // ✅ Fetch user details from Firestore
        const userRef = doc(db, "users", user.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setUserName(userSnap.data().firstName || "");
        }

        // ✅ Redirect to returnUrl after login
        navigate(returnUrl);
      } else {
        setIsAuthenticated(false);
        setUserName("");
      }
    });
    return () => unsubscribe();
  }, [navigate, returnUrl]);

  // ✅ Reset Form & Messages on Mode Change
  useEffect(() => {
    setFormData(initialFormState);
    setError("");
    setSuccess("");
  }, [mode, initialFormState]);

  // ✅ Handle Input Changes
  const handleChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  // ✅ Signup & Login Function
  const handleAuth = async () => {
    setLoading(true);
    setError("");
    setSuccess("");

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\+?[0-9][0-9]{7,14}$/;
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{12,}$/;

    try {
      if (mode === "signup") {
        if (!emailRegex.test(formData.email)) {
          setError("Bitte gib eine gültige E-Mail-Adresse ein.");
          setLoading(false);
          return;
        }

        if (formData.phone && !phoneRegex.test(formData.phone)) {
          setError("Die Telefonnummer ist ungültig.");
          setLoading(false);
          return;
        }

        if (!passwordRegex.test(formData.password)) {
          setError(
            "Das Passwort muss mindestens 12 Zeichen lang sein und eine Zahl, einen Großbuchstaben und ein Sonderzeichen enthalten."
          );
          setLoading(false);
          return;
        }

        if (formData.password !== formData.confirmPassword) {
          setError("Die Passwörter stimmen nicht überein.");
          setLoading(false);
          return;
        }

        // ✅ Create user in Firebase Auth
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          formData.email,
          formData.password
        );

        // ✅ Send email verification
        await sendEmailVerification(userCredential.user);

        // ✅ Store user in Firestore
        const userRef = doc(db, "users", userCredential.user.uid);
        await setDoc(userRef, {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          phone: formData.phone || "",
          createdAt: new Date(),
          verified: false, // ✅ Mark as not verified yet
        });

        setSuccess(
          "✅ Konto wurde erfolgreich erstellt! Bitte überprüfe deine E-Mail und bestätige dein Konto."
        );
        setMode("login");
      } else {
        // ✅ Firebase login
        const userCredential = await signInWithEmailAndPassword(
          auth,
          formData.email,
          formData.password
        );

        // ✅ Check if email is verified before login
        if (!userCredential.user.emailVerified) {
          setError("❌ Bitte bestätige deine E-Mail, bevor du dich einloggst.");
          await sendEmailVerification(userCredential.user); // Re-send verification if needed
          setLoading(false);
          return;
        }

        navigate(returnUrl);
      }
    } catch (err) {
      setError(`❌ Fehler: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  // ✅ Password Reset Function
  const handleForgotPassword = async () => {
    setLoading(true);
    setError("");
    setSuccess("");

    try {
      await sendPasswordResetEmail(auth, formData.email);
      setSuccess(
        "📩 Passwort-Zurücksetzung wurde gesendet. Überprüfe deine E-Mails."
      );
      setMode("login");
    } catch (err) {
      setError(`❌ Fehler: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  // ✅ Logout Function
  const handleLogout = async () => {
    await signOut(auth);
    setIsAuthenticated(false);
    setUserName("");
    navigate("/");
  };

  // 🎭 Framer Motion Variants
  const inputVariants = {
    hidden: { opacity: 0, y: 10 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: { delay: i * 0.05, duration: 0.4, ease: "easeOut" },
    }),
  };

  return (
    <motion.div
      className="login-container"
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      transition={{ duration: 0.5, ease: "easeInOut" }}
    >
      {isAuthenticated ? (
        <>
          <h2 className="login-title">
            Willkommen{userName ? `, ${userName}` : ""}!
          </h2>
          <p>
            Dein Account wurde erfolgreich erstellt. Du kannst jetzt auf deine
            Termine zugreifen, neue Termine buchen oder Termine stornieren.
          </p>
          <motion.button
            className="btn btn-outline"
            whileHover={{ scale: 1.1 }}
            onClick={handleLogout}
          >
            Abmelden
          </motion.button>
        </>
      ) : (
        <>
          <h2 className="login-title">
            {mode === "signup"
              ? "Registrieren"
              : mode === "forgotPassword"
              ? "Passwort zurücksetzen"
              : "Anmelden"}
          </h2>

          {error && <motion.p className="error-text">{error}</motion.p>}
          {success && <motion.p className="success-text">{success}</motion.p>}

          <motion.div key={mode} initial="hidden" animate="visible">
            {mode === "signup" && (
              <>
                <motion.input
                  className="login-input"
                  type="text"
                  name="firstName"
                  placeholder="Vorname"
                  value={formData.firstName}
                  onChange={handleChange}
                  variants={inputVariants}
                  custom={0}
                />
                <motion.input
                  className="login-input"
                  type="text"
                  name="lastName"
                  placeholder="Nachname"
                  value={formData.lastName}
                  onChange={handleChange}
                  variants={inputVariants}
                  custom={1}
                />
                <motion.input
                  className="login-input"
                  type="tel"
                  name="phone"
                  placeholder="Telefonnummer (optional)"
                  value={formData.phone}
                  onChange={handleChange}
                  variants={inputVariants}
                  custom={2}
                />
              </>
            )}
            <motion.input
              className="login-input"
              type="email"
              name="email"
              placeholder="E-Mail-Adresse"
              value={formData.email}
              onChange={handleChange}
              variants={inputVariants}
              custom={3}
            />{" "}
            {/* ✅ Password Input */}
            {mode !== "forgotPassword" && (
              <div className="input-container">
                <motion.input
                  className="login-input"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Passwort"
                  value={formData.password}
                  onChange={handleChange}
                  variants={inputVariants}
                  custom={4}
                />
                {/* ✅ Toggle Button Inside Input */}
                <span className="toggle-password" onClick={togglePassword}>
                  <i
                    className={showPassword ? "fas fa-eye-slash" : "fas fa-eye"}
                  ></i>
                </span>
              </div>
            )}
            {/* ✅ Confirm Password Input (Signup Only) */}
            {mode === "signup" && (
              <div className="input-container">
                <motion.input
                  className="login-input"
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  placeholder="Passwort bestätigen"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  variants={inputVariants}
                  custom={4}
                />
                {/* ✅ Toggle Button Inside Input */}
                <span
                  className="toggle-password"
                  onClick={toggleConfirmPassword}
                >
                  <i
                    className={
                      showConfirmPassword ? "fas fa-eye-slash" : "fas fa-eye"
                    }
                  ></i>
                </span>
              </div>
            )}
          </motion.div>
          {mode === "signup" && (
            <p className="privacy-note">
              Mit dem Erstellen eines Benutzerkontos stimmst du unserer{" "}
              <NavLink target="_blank" to="/legal">
                Datenschutzbestimmung
              </NavLink>{" "}
              zu.
            </p>
          )}
          <motion.button
            className="btn btn-highlight"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={
              mode === "forgotPassword" ? handleForgotPassword : handleAuth
            }
            disabled={loading}
          >
            {loading
              ? "Wird verarbeitet..."
              : mode === "signup"
              ? "Konto erstellen"
              : mode === "forgotPassword"
              ? "Passwort zurücksetzen"
              : "Anmelden"}
          </motion.button>

          <div className="switch-options">
            <motion.button
              className="btn btn-outline"
              onClick={() => setMode("forgotPassword")}
              whileHover={{ scale: 1.1 }}
            >
              Passwort vergessen?
            </motion.button>
            <motion.button
              className="btn btn-outline"
              onClick={() => setMode(mode === "signup" ? "login" : "signup")}
              whileHover={{ scale: 1.1 }}
            >
              {mode === "signup"
                ? "Bereits ein Konto? Anmelden"
                : "Konto erstellen"}
            </motion.button>
          </div>
        </>
      )}
    </motion.div>
  );
};

export default LoginComponent;
