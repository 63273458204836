import React, { useState, useEffect, useCallback, useRef } from "react";
import { auth, db } from "../../firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, collection, query, orderBy, limit, getDocs, where } from "firebase/firestore";
import "./topbar.css";
import LoginButton from "../login/LoginButton";

const Topbar = () => {
  const ref = useRef(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [initials, setInitials] = useState("");
  const [nextAppointment, setNextAppointment] = useState(null);

  // ✅ Fetch user details from Firestore
  const fetchUserDetails = useCallback(async (userId) => {
    try {
      const userRef = doc(db, "users", userId);
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
        const userData = userSnap.data();
        setFirstName(userData.firstName || "");
        setLastName(userData.lastName || "");
      }
    } catch (error) {
      console.error("❌ Fehler beim Laden der Benutzerdaten:", error);
    }
  }, []);

  // ✅ Fetch the next upcoming appointment
  const fetchNextAppointment = useCallback(async (userId) => {
    try {
      const appointmentsRef = collection(db, "users", userId, "appointments");
      const now = new Date().toISOString();

      const q = query(
        appointmentsRef,
        where("start", ">", now), // ✅ Only get future appointments
        orderBy("start", "asc"), // ✅ Get the closest upcoming one
        limit(1)
      );

      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const nextAppointmentData = querySnapshot.docs[0].data();
        setNextAppointment(nextAppointmentData);
      } else {
        setNextAppointment(null);
      }
    } catch (error) {
      console.error("❌ Fehler beim Laden des nächsten Termins:", error);
    }
  }, []);

  // ✅ Listen for authentication state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuthenticated(true);
        fetchUserDetails(user.uid);
        fetchNextAppointment(user.uid);
      } else {
        setIsAuthenticated(false);
        setFirstName("");
        setLastName("");
        setInitials("");
        setNextAppointment(null);
      }
    });

    return () => unsubscribe();
  }, [fetchUserDetails, fetchNextAppointment]);

  // ✅ Generate user initials
  useEffect(() => {
    if (firstName && lastName) {
      setInitials(`${firstName[0]}${lastName[0]}`.toUpperCase());
    }
  }, [firstName, lastName]);

  // ✅ Format timeslot to readable format
  const formatTimeslot = useCallback((appointment) => {
    if (!appointment?.start || !appointment?.end) return null;
    try {
      const startDate = new Date(appointment.start);
      const formattedStartDate = startDate.toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "long",
        year: "numeric",
      });
      const formattedStartTime = startDate.toLocaleTimeString("de-DE", {
        hour: "2-digit",
        minute: "2-digit",
      });

      return `${formattedStartDate} - ${formattedStartTime}`;
    } catch (error) {
      console.error("❌ Error formatting timeslot:", error);
      return "Ungültiges Datum";
    }
  }, []);

  // ✅ Handle settings modal toggle (Ensured to work with a single click)
  const toggleSettingsModal = () => {
    // ✅ Use localStorage directly instead of state
    const currentModalState = localStorage.getItem("modal_status") === "true";
    const newModalState = !currentModalState;
  
    localStorage.setItem("modal_status", newModalState ? "true" : "false");
  
    // ✅ Ensure UI updates correctly
    window.dispatchEvent(new Event("modal_toggle"));
  };
  


  return (
    <div className="topbar" id="topbar" ref={ref}>
      <div className="topbar-container">
        {/* ✅ Show initials & greeting only when authenticated */}
        {isAuthenticated && initials && <div className="user-icon">{initials}</div>}
        {isAuthenticated && firstName && (
          <p className="topbar-user-greeting" style={{ marginRight: "auto" }}>
            Hey, {firstName}!
          </p>
        )}

        {/* ✅ Show next appointment only if it exists */}
        {nextAppointment && (
          <p className="topbar-user-appointment" style={{ marginRight: "auto" }}>
            <i className="fas fa-calendar" style={{ marginRight: ".5rem" }}></i>
            <span>Dein nächster Termin:</span>{" "}
            <strong>{formatTimeslot(nextAppointment)}</strong>
          </p>
        )}

        {/* ✅ Always show login/logout button */}
        <LoginButton />

        {/* ✅ Settings Button (Restored Modal Toggle Properly) */}
        <button className="user-settings" onClick={toggleSettingsModal}>
          <i className="fas fa-sliders" />
        </button>
      </div>
    </div>
  );
};

export default Topbar;
