import React, { useState, useEffect, useCallback } from "react";
import { auth, db } from "../../firebaseConfig";
import {
  doc,
  getDoc,
  updateDoc,
  collection,
  query,
  orderBy,
  where,
  getDocs,
  deleteDoc,
} from "firebase/firestore";
import apiRequest from "../../auth/auth";
import { listItems } from "../../data/DataDefault"; // ✅ Import service list
import "./myarea.css";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { TbRazor } from "react-icons/tb";
import { RiScissorsLine } from "react-icons/ri";
import { FaMask, FaStar } from "react-icons/fa";

const MyAreaContent = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState({ firstName: "", lastName: "", phone: "" });
  const [appointments, setAppointments] = useState([]);
  const [editing, setEditing] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    mail: "",
    phone: "",
  });

  // ✅ Logout Function
  const handleLogout = async () => {
    try {
      await signOut(auth);

      // ✅ Clear Firebase-related localStorage items
      Object.keys(localStorage).forEach((key) => {
        if (key.startsWith("firebase:")) {
          localStorage.removeItem(key);
        }
      });

      navigate("/");
      window.location.reload(); // Force UI refresh
    } catch (error) {
      console.error("❌ Fehler beim Abmelden:", error);
      alert("Fehler beim Abmelden. Bitte erneut versuchen.");
    }
  };

  // ✅ Fetch user info from Firebase
  const fetchUserData = useCallback(async (userId) => {
    try {
      const userRef = doc(db, "users", userId);
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
        const userData = userSnap.data();
        setUser(userData);
        setFormData(userData);
      }
    } catch (error) {
      console.error("❌ Fehler beim Laden der Benutzerdaten:", error);
    }
  }, []);

  const fetchAppointments = useCallback(async (userId) => {
    try {
      const appointmentsRef = collection(db, "users", userId, "appointments");
      const now = new Date().toISOString();

      const q = query(
        appointmentsRef,
        where("start", ">", now), // Get only future appointments
        orderBy("start", "asc") // Sort by closest
      );

      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const appointmentsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Fetch detailed appointment information from eTermin API
        const detailedAppointments = await Promise.all(
          appointmentsData.map(async (appointment) => {
            const { appointmentIID } = appointment;
            if (!appointmentIID) {
              console.warn(`Appointment ${appointment.id} is missing IID.`);
              return appointment;
            }

            const url = `https://www.etermin.net/api/appointment?appid=${appointmentIID}`;
            const headers = {
              Accept: "application/json",
              // Include other necessary headers such as authentication headers
            };
            try {
              const appointmentDetails = await apiRequest(
                url,
                "GET",
                null,
                headers
              );

              // Log the API response
              console.log(
                `API Response for appointmentIID ${appointmentIID}:`,
                appointmentDetails
              );

              const firestoreID = Number(appointmentIID);
              const apiExternalID = appointmentDetails[0].ID;
              const isMatch = firestoreID === apiExternalID;
              console.log(
                `Comparison result for appointment ${appointment.id}:`,
                isMatch
              );

              return { ...appointment, details: appointmentDetails };
            } catch (error) {
              console.error(
                `Error fetching details for appointment IID ${appointmentIID}:`,
                error
              );

              // Delete the appointment document from Firestore
              try {
                const appointmentDocRef = doc(
                  db,
                  "users",
                  userId,
                  "appointments",
                  appointment.id
                );
                await deleteDoc(appointmentDocRef);
                console.log(
                  `Appointment ${appointment.id} successfully deleted from Firestore.`
                );
              } catch (deleteError) {
                console.error(
                  `Error deleting appointment ${appointment.id} from Firestore:`,
                  deleteError
                );
              }

              return null; // Indicate that this appointment was deleted
            }
          })
        );

        // Filter out any null values resulting from deletions
        const validAppointments = detailedAppointments.filter(
          (appt) => appt !== null
        );

        setAppointments(validAppointments);
      } else {
        setAppointments([]); // Clear appointments if no future appointments are found
      }
    } catch (error) {
      console.error("Error loading appointments:", error);
    }
  }, []);

  // ✅ Handle input change in form
  const handleInputChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  // ✅ Save updated user info to Firebase
  const handleSave = async () => {
    try {
      const userId = auth.currentUser?.uid;
      if (!userId) return;

      const userRef = doc(db, "users", userId);
      await updateDoc(userRef, {
        firstName: formData.firstName,
        lastName: formData.lastName,
        phone: formData.phone,
      });

      setUser(formData);
      setEditing(false);
      alert("✅ Profil erfolgreich aktualisiert!");
    } catch (error) {
      console.error("❌ Fehler beim Aktualisieren des Profils:", error);
    }
  };

  // ✅ Load user info and appointments on component mount
  useEffect(() => {
    const userId = auth.currentUser?.uid;
    if (userId) {
      fetchUserData(userId);
      fetchAppointments(userId);
    }
  }, [fetchUserData, fetchAppointments]);

  // ✅ Get category icon based on service category
  const getCategoryIcon = (category) => {
    if (!category) return null;
    switch (category) {
      case "VIP":
        return <FaStar />;
      case "Haare":
        return <RiScissorsLine />;
      case "Bart":
        return <TbRazor />;
      case "Kosmetik":
        return <FaMask />;
      default:
        return null;
    }
  };

  const deleteAppointment = async (appointmentId) => {
    if (!appointmentId || appointmentId === "N/A") {
      alert("❌ Fehler: Kein gültiger Termin gefunden.");
      return;
    }

    // ✅ Confirm deletion
    if (
      !window.confirm(
        "Möchtest du diesen Termin wirklich stornieren? Diese Aktion kann nicht rückgängig gemacht werden."
      )
    ) {
      return;
    }

    const payload = new URLSearchParams({
      externalid: appointmentId,
      sendemail: "1",
      sendcopy: "1",
      msgtype: "11",
      sync: "1"
    }).toString();

    const headers = { "Content-Type": "text/plain" };

    try {
      const response = await apiRequest(
        "https://www.etermin.net/api/appointment",
        "DELETE",
        payload,
        headers
      );

      console.log(payload)
      // ✅ Parse XML Response
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(response, "application/xml");
      const status = xmlDoc.getElementsByTagName("status")[0]?.textContent;
      const statusMsg =
        xmlDoc.getElementsByTagName("statusmsg")[0]?.textContent;

      if (status === "200") {
        console.log("✅ Termin erfolgreich gelöscht", statusMsg);

        // ✅ Remove appointment from Firestore (if user is logged in)
        if (auth.currentUser?.uid) {
          const userRef = doc(
            db,
            "users",
            auth.currentUser.uid,
            "appointments",
            appointmentId
          );

          // ✅ Double-check Firestore reference before deleting
          if (
            appointmentId &&
            appointmentId.trim() !== "" &&
            appointmentId !== "N/A"
          ) {
            await deleteDoc(userRef);
            console.log(`🔥 Termin ${appointmentId} aus Firestore gelöscht.`);
          } else {
            console.warn(
              "⚠️ Ungültige appointmentId, Firestore-Delete übersprungen."
            );
          }
        }

        // ✅ Update UI state
        setAppointments((prev) =>
          prev.filter((appt) => appt.id !== appointmentId)
        );

        alert("✅ Termin wurde erfolgreich storniert.");
      } else {
        console.error("❌ Fehler beim Löschen des Termins:", status, statusMsg);
        alert("Fehler beim Löschen des Termins.");
      }
    } catch (error) {
      console.error("❌ Fehler beim Löschen des Termins:", error);
      alert("Fehler beim Löschen des Termins.");
    }
  };

  // ✅ Delete User Account Function
  const handleDeleteAccount = async () => {
    if (
      !window.confirm(
        "❌ Bist du sicher, dass du dein Konto löschen möchtest? Diese Aktion kann nicht rückgängig gemacht werden."
      )
    ) {
      return;
    }

    try {
      const userId = auth.currentUser?.uid;
      if (!userId) {
        alert("❌ Kein Benutzer gefunden.");
        return;
      }

      // ✅ Delete all user appointments from Firestore
      const appointmentsRef = collection(db, "users", userId, "appointments");
      const querySnapshot = await getDocs(appointmentsRef);
      const deletePromises = querySnapshot.docs.map((doc) =>
        deleteDoc(doc.ref)
      );
      await Promise.all(deletePromises);
      console.log("🔥 Alle Termine gelöscht.");

      // ✅ Delete user data from Firestore
      await deleteDoc(doc(db, "users", userId));
      console.log("🔥 Benutzerdaten gelöscht.");

      // ✅ Delete user from Firebase Auth
      await auth.currentUser.delete();
      console.log("🔥 Benutzerkonto gelöscht.");

      alert("✅ Dein Konto wurde erfolgreich gelöscht.");
      navigate("/");
      window.location.reload();
    } catch (error) {
      console.error("❌ Fehler beim Löschen des Kontos:", error);
      alert("Fehler beim Löschen des Kontos. Bitte erneut versuchen.");
    }
  };

  const downloadICS = (appointment) => {
    if (!appointment.start || !appointment.end) return;

    const timezone = "Europe/Berlin";

    // ✅ Manually format times to avoid UTC shift
    const formatLocalDateTime = (dateStr) => {
      const date = new Date(dateStr);
      return (
        date.getFullYear() +
        ("0" + (date.getMonth() + 1)).slice(-2) + // Month (zero-padded)
        ("0" + date.getDate()).slice(-2) +
        "T" + // Day (zero-padded)
        ("0" + date.getHours()).slice(-2) + // Hours (zero-padded)
        ("0" + date.getMinutes()).slice(-2) + // Minutes (zero-padded)
        "00"
      ); // Fixed seconds to prevent parsing issues
    };

    const dtstart = formatLocalDateTime(appointment.start);
    const dtend = formatLocalDateTime(appointment.end);

    // ✅ Get service names (comma-separated)
    const serviceNames =
      appointment.service
        ?.map((s) => s.name)
        .filter(Boolean)
        .join(", ") || "Termin";

    // ✅ Define website URL
    const websiteURL = "https://xs-barbershop.com";

    // ✅ Fix location display
    const location = "XS-Barbershop - Fabrikstraße 32 - 63225 Langen";
    const appleLocation = "geo:50.003,8.674"; // Replace with actual lat,long if needed

    // ✅ Build the detailed description
    const description = `Du erhältst: ${serviceNames}\n\nDu willst deinen Termin stornieren? Besuche unsere Website:\n${websiteURL}\n\nOrt: ${location}`;

    // ✅ ICS content with correct timezone handling
    const icsContent =
      "BEGIN:VCALENDAR\r\n" +
      "VERSION:2.0\r\n" +
      "CALSCALE:GREGORIAN\r\n" +
      "PRODID:-//XS-Barbershop//NONSGML v1.0//EN\r\n" +
      "BEGIN:VEVENT\r\n" +
      "UID:" +
      appointment.id +
      "\r\n" +
      "DTSTAMP:" +
      new Date().toISOString().replace(/[-:]/g, "").split(".")[0] +
      "Z\r\n" +
      "DTSTART;TZID=" +
      timezone +
      ":" +
      dtstart +
      "\r\n" +
      "DTEND;TZID=" +
      timezone +
      ":" +
      dtend +
      "\r\n" +
      "SUMMARY:" +
      serviceNames +
      " im XS-Barbershop\r\n" +
      "DESCRIPTION:" +
      description +
      "\r\n" +
      "LOCATION:" +
      location +
      "\r\n" +
      "URL:" +
      websiteURL +
      "\r\n" +
      "X-APPLE-STRUCTURED-LOCATION;VALUE=URI;X-TITLE=XS-Barbershop:" +
      appleLocation +
      "\r\n" +
      "BEGIN:VALARM\r\n" +
      "ACTION:DISPLAY\r\n" +
      "DESCRIPTION:Erinnerung an deinen Termin (" +
      serviceNames +
      ")\r\n" +
      "TRIGGER;RELATED=START:-PT24H\r\n" +
      "END:VALARM\r\n" +
      "END:VEVENT\r\n" +
      "END:VCALENDAR\r\n";

    // ✅ Generate a clean filename
    const cleanFilename = `Termin-${
      serviceNames.replace(/_/g, "").replace(/\s+/g, " ") || "Event"
    }.ics`;

    // ✅ Create and download the ICS file
    const blob = new Blob([icsContent], {
      type: "text/calendar;charset=utf-8",
    });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = cleanFilename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <div className="myarea-container">
      <h3>
        Willkommen, {user.firstName}!{" "}
        <button className="edit-profile" onClick={handleLogout}>
          Logout
        </button>
      </h3>
      <div className="appointments-section two-thirds">
        <h5>Meine Termine</h5>
        {appointments.length > 0 ? (
          <ul className="list-items appointment-items">
            {appointments.map((appt) => {
              // ✅ Find service details
              const serviceDetails = appt.service?.map((s) =>
                listItems.find((item) => item.serviceId === s.serviceId)
              );

              return (
                <li key={appt.id} className="list-item">
                  {/* ✅ Render each service separately with its own icon */}
                  {serviceDetails.map((s, index) => (
                    <div key={index} className="service-item">
                      <span className="list-item-category">
                        {s && getCategoryIcon(s.category)}
                      </span>
                      <span>{s?.name}</span>
                    </div>
                  ))}

                  {/* ✅ Formatted Date and Time */}
                  <div className="appointment-datetime">
                    {new Date(appt.start).toLocaleDateString("de-DE", {
                      weekday: "long",
                      day: "2-digit",
                      month: "long",
                      year: "numeric",
                    })}{" "}
                    {new Date(appt.start).toLocaleTimeString("de-DE", {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                    {" - "}
                    {new Date(appt.end).toLocaleTimeString("de-DE", {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                    <span onClick={() => downloadICS(appt)}>
                      In Kalender speichern
                    </span>
                  </div>
                  {/* ✅ Delete Button */}
                  <button
                    className="edit-profile"
                    onClick={() => deleteAppointment(appt.id)}
                  >
                    Termin stornieren
                  </button>
                </li>
              );
            })}
          </ul>
        ) : (
          <p>Keine bevorstehenden Termine.</p>
        )}
      </div>
      <div className="profile-section one-third">
        <h5>Meine Daten</h5>
        {editing ? (
          <div className="user-card">
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              placeholder="Vorname"
            />
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              placeholder="Nachname"
            />
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              placeholder="Telefonnummer"
            />
            <div className="user-card-actions">
              <button
                className="btn btn-outline cancel"
                onClick={() => setEditing(false)}
              >
                Abbrechen
              </button>
              <button className="btn btn-highlight save" onClick={handleSave}>
                Speichern
              </button>
              <button className="edit-profile" onClick={handleDeleteAccount}>
                Account löschen
              </button>
            </div>
          </div>
        ) : (
          <div className="user-card">
            <p className="user-name">
              {user.firstName} {user.lastName}
            </p>
            <p className="user-phone">{user.phone || "Nicht angegeben"}</p>
            <p className="user-mail">{user.email || "Nicht angegeben"}</p>
            <button className="edit-profile" onClick={() => setEditing(true)}>
              Profil bearbeiten
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyAreaContent;
