import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { auth } from "../../firebaseConfig";
import { signOut, onAuthStateChanged } from "firebase/auth";

const LoginButton = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  
  // ✅ Track auth state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
    });

    return () => unsubscribe();
  }, []);

  // ✅ Properly navigate to "Mein Bereich" without forced redirection
  const handleRedirect = () => {
    if (isAuthenticated) {
      navigate("/myarea");
    } else {
      navigate("/login?returnUrl=/myarea"); // ✅ Redirect back to MyArea after login
    }
  };

  // ✅ Ensure logout doesn't trigger unwanted redirects
  const handleLogout = async () => {
    await signOut(auth);

    // ✅ Clear only Firebase-related storage
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith("firebase:")) {
        localStorage.removeItem(key);
      }
    });

    // ✅ Navigate home **only if the user was on a protected route**
    if (location.pathname.startsWith("/myarea")) {
      navigate("/");
    }
  };

  return (
    <div>
      {isAuthenticated ? (
        <>
          <button className="btn btn-highlight btn-login" onClick={handleLogout}>
            Logout
          </button>
        </>
      ) : (
        <button className="btn btn-highlight btn-login" onClick={handleRedirect}>
          Login / Signup
        </button>
      )}
    </div>
  );
};

export default LoginButton;
