import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import './index.css';
import {
  About,
  Home,
  Contact,
  Legal,
  Customization,
  Projects,
  Services,
  Test,
  NotFound,
  Termin
} from './pages';
import {
  Footer,
  NavigationBar,
  Topbar,
  ToTopButton,
  Fullscreen
} from './components';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import ScrollToTop from './hooks/ScrollToTop';
import LocalStorageCheck from './helpers/LocalStorageCheck';
import Login from './pages/login/Login';
import MyArea from './pages/myarea/MyArea';
import ProtectedRoute from './helpers/ProtectedRoute';

const container = document.getElementById('root');
const root = createRoot(container);

// ✅ Theme Colors from Local Storage
let primaryColor = localStorage.getItem('primarycolor') || "#1e1e1e";
let secondaryColor = localStorage.getItem('secondarycolor') || "#eaeaea";
let tertiaryColor = localStorage.getItem('tertiarycolor') || "#0d0d0d";
let backgroundColor = localStorage.getItem('backgroundcolor') || "#f0f0f0";
let activeColor = localStorage.getItem('activecolor') || "#e77100";

document.documentElement.style.setProperty('--primary-color', primaryColor);
document.documentElement.style.setProperty('--secondary-color', secondaryColor);
document.documentElement.style.setProperty('--tertiary-color', tertiaryColor);
document.documentElement.style.setProperty('--background-color', backgroundColor);
document.documentElement.style.setProperty('--active-color', activeColor);

localStorage.setItem('primarycolor', primaryColor);
localStorage.setItem('secondarycolor', secondaryColor);
localStorage.setItem('tertiarycolor', tertiaryColor);
localStorage.setItem('backgroundcolor', backgroundColor);
localStorage.setItem('activecolor', activeColor);

root.render(
  <React.StrictMode>
    <Router>
      <ScrollToTop />
      <LocalStorageCheck />
      <Fullscreen isLoggedIn={true} />
      <header id='header'>
        <Topbar />
        <NavigationBar />
      </header>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/legal" element={<Legal />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/services" element={<Services />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/myarea"
          element={
            <ProtectedRoute>
              <MyArea />
            </ProtectedRoute>
          }
        />
        <Route path="/customization" element={<Customization />} />
        <Route path="/test" element={<Test />} />
        <Route path="/termin/*" element={<Termin />} />
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
      <ToTopButton />
      <Footer />
    </Router>
  </React.StrictMode>
);

reportWebVitals();
serviceWorkerRegistration.register();
