import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { auth } from "../firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";

const ProtectedRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
    });

    return () => unsubscribe();
  }, []);

  // ✅ Prevent flickering when checking authentication state
  if (isAuthenticated === null) {
    return <div>Bitte warte einen Moment...</div>; // ✅ Show a loading screen while checking auth
  }

  return isAuthenticated ? (
    children
  ) : (
    <Navigate to={`/login?returnUrl=${encodeURIComponent(location.pathname)}`} replace />
  );
};

export default ProtectedRoute;
