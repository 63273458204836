import { React } from "react";
import "./contact.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import {
  Box,
  Grid,
  Hero,
  Cta,
  Divider,
  Modal,
  Preloader,
  Userform,
  Headline,
} from "../../components";
import { HomepageModal } from "../../data/DataHomepage";
import {
  ContactpageUserform,
  ContactpageBox,
  ContactpageCta,
  ContactpageHero,
  ContactpageMeta,
  ContactpageHeadline,
} from "../../data/DataContactpage";
import { CompanyName } from "../../data";

const CompanyNameTitle = CompanyName;

function Contact() {
  const modal = HomepageModal;
  const box = ContactpageBox;
  const hero = ContactpageHero;
  const cta = ContactpageCta;
  const meta = ContactpageMeta;
  const userform = ContactpageUserform;
  const headline = ContactpageHeadline;

  return (
    <div className="content">
      <HelmetProvider>
        <Grid
          grid={{
            className: "page",
            pageName: "contact-page",
            isFull: true,
          }}
        >
          <Helmet>
            <html lang="de" />
            <title>{CompanyNameTitle} - Kontakt</title>
            <meta name="description" content={meta} />
          </Helmet>
          <Hero hero={hero} />
        </Grid>
        <Grid
          grid={{
            className: "page",
            pageName: "contact-page",
            isFull: false,
          }}
        >
          <Divider
            divider={{
              dividerHeight: "50px",
              dividerHeightMobile: "25px",
              id: 1,
            }}
          />
          <Headline
            headline={headline.filter((headline) => headline.section === 1)}
          />
          <Userform
            customizable={false}
            editable={true}
            sendForm={true}
            userform={userform}
          />
          <Divider
            divider={{
              dividerHeight: "200px",
              dividerHeightMobile: "100px",
              id: 1,
            }}
          />
          <Cta cta={cta.filter((cta) => cta.section === 1)} />
          <Divider
            divider={{
              dividerHeight: "200px",
              dividerHeightMobile: "100px",
              id: 1,
            }}
          />
          <Box fromJson={true} box={box.filter((box) => box.section === 1)} />
          <Divider
            divider={{
              dividerHeight: "200px",
              dividerHeightMobile: "100px",
              id: 1,
            }}
          />
          <Modal modal={modal.filter((modal) => modal.section === 1)} />
        </Grid>
        <Preloader />
      </HelmetProvider>
    </div>
  );
}

export default Contact;
