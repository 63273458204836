import { React } from "react";
import "./legal.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import {
  Box,
  Grid,
  Preloader,
  Hero,
  Modal,
  Headline,
  Divider,
} from "../../components";
import { CompanyName } from "../../data";
import {
  LegalpageBox,
  LegalpageHero,
  LegalpageMeta,
  LegalpageHeadline,
} from "../../data/DataLegalpage";
import { HomepageModal } from "../../data/DataHomepage";

const CompanyNameTitle = CompanyName;

function Legal() {
  const box = LegalpageBox;
  const hero = LegalpageHero;
  const meta = LegalpageMeta;
  const modal = HomepageModal;
  const headline = LegalpageHeadline;

  return (
    <div className="content">
      <HelmetProvider>
        <Grid
          grid={{
            className: "page",
            pageName: "legal-page",
            isFull: true,
          }}
        >
          <Helmet>
            <html lang="de" />
            <title>{CompanyNameTitle} - Rechtliches</title>
            <meta name="description" content={meta} />
          </Helmet>
          <Hero hero={hero} />
        </Grid>
        <Grid
          grid={{
            className: "page",
            pageName: "legal-page",
            isFull: false,
          }}
        >
          <Divider
            divider={{
              dividerHeight: "50px",
              dividerHeightMobile: "25px",
              id: 1,
            }}
          />
          <Headline
            headline={headline.filter((headline) => headline.section === 1)}
          />
          <Divider
            divider={{
              dividerHeight: "100px",
              dividerHeightMobile: "50px",
              id: 1,
            }}
          />
          <Box fromJson={true} box={box.filter((box) => box.section === 1)} />
          <Divider
            divider={{
              dividerHeight: "200px",
              dividerHeightMobile: "100px",
              id: 1,
            }}
          />
          <Modal modal={modal.filter((modal) => modal.section === 1)} />
        </Grid>
        <Preloader />
      </HelmetProvider>
    </div>
  );
}

export default Legal;
