import React, { useState, useEffect, useRef } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import apiRequest from "../../auth/auth";
import ServiceSelection from "../booking/ServiceSelection";
import CalendarSelection from "../booking/CalendarSelection";
import TimeSlotSelection from "../booking/TimeSlotSelection";
import UserDataForm from "../booking/UserDataForm";
import Confirmation from "../booking/Confirmation";
import BookingSuccess from "../booking/BookingSuccess";
import ProgressBar from "../booking/ProgressBar";
import useOnlineStatus from "../../hooks/useOnlineStatus";
import { listItems } from "../../data/DataDefault";
import { doc, collection, addDoc, setDoc } from "firebase/firestore"; // ✅ Firestore imports
import { auth, db } from "../../firebaseConfig"; // ✅ Firebase Config
import "./booking.css";

const Booking = () => {
  const isOnline = useOnlineStatus();
  const navigate = useNavigate();
  const location = useLocation();
  const bookingContainerRef = useRef(null);

  const [bookingData, setBookingData] = useState(() => {
    return (
      JSON.parse(localStorage.getItem("bookingData")) || {
        service: [],
        date: null,
        time: null,
        user: {
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
        },
      }
    );
  });

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const serviceId = query.get("serviceId");

    if (serviceId) {
      // ✅ Remove length check, always update when a serviceId is present
      const filteredItem = listItems.find(
        (item) => item.serviceId === serviceId && item.bookable
      );

      if (filteredItem) {
        console.log(serviceId);
        setBookingData((prev) => ({ ...prev, service: [filteredItem] }));
        console.log(bookingData);
      } else {
        console.warn("Invalid serviceId received:", serviceId);
      }
    }
  }, [location.search, bookingData]); // ✅ No need to check bookingData.service.length

  const handleServiceSelection = (selectedServices) => {
    if (selectedServices.length > 0) {
      setBookingData((prev) => ({ ...prev, service: selectedServices }));
      navigate("/calendar");
    }
  };

  useEffect(() => {
    localStorage.setItem("bookingData", JSON.stringify(bookingData));
  }, [bookingData]);

  const fetchAvailableTimeslots = async (serviceIds, date) => {
    if (!serviceIds || serviceIds.length === 0 || !date) return [];

    const formattedDate = new Date(date).toISOString().split("T")[0];
    const validServiceIds = serviceIds.map((service) => service.serviceId);
    const url = `https://www.etermin.net/api/timeslots?date=${formattedDate}&serviceid=${validServiceIds.join(
      ","
    )}&rangesearch=0&capacity=1&caching=false&duration=0&cluster=false&slottype=0&fillcalendarstrategy=0&showavcap=false&appfuture=180&appdeadline=60&msdcm=0&oneoff=null&appdeadlinewm=0`;

    try {
      const response = await apiRequest(url, "GET");
      return response.map((slot) => ({
        ...slot,
        formattedTime: `${new Date(slot.start).toLocaleTimeString("de-DE", {
          hour: "2-digit",
          minute: "2-digit",
        })} - ${new Date(slot.end).toLocaleTimeString("de-DE", {
          hour: "2-digit",
          minute: "2-digit",
        })}`,
      }));
    } catch (error) {
      console.error("Error fetching timeslots:", error);
      return [];
    }
  };

  const [appointmentCreated, setAppointmentCreated] = useState(false);
  useEffect(() => {
    if (!bookingData) return; // ✅ Ensure bookingData is loaded

    const requiredSteps = {
      "/termin/calendar": bookingData.service.length > 0,
      "/termin/timeslot": bookingData.date,
      "/termin/userdata": bookingData.time,
      "/termin/confirmation": bookingData.user?.firstName, // Ensures user data exists
      "/termin/success": appointmentCreated, // ✅ Check appointment state
    };

    if (
      location.pathname in requiredSteps &&
      !requiredSteps[location.pathname]
    ) {
      console.warn(`Blocked access to ${location.pathname}`);
      navigate("/termin"); // ✅ Redirect to the first valid step
    }
  }, [location.pathname, bookingData, appointmentCreated, navigate]);

  const handleConfirmBooking = async () => {
    console.log("📌 Debugging bookingData:", bookingData);

    if (
      !bookingData ||
      !Array.isArray(bookingData.service) ||
      bookingData.service.length === 0
    ) {
      console.error(
        "🚨 Fehler: Buchungsdaten fehlen oder sind ungültig.",
        bookingData
      );
      alert("Fehler: Buchungsdaten fehlen. Bitte erneut versuchen.");
      return;
    }

    const {
      service,
      date,
      time,
      user,
      onWaitlist,
      waitlistStartTime,
      waitlistEndTime,
    } = bookingData;

    if (
      !date ||
      !time ||
      !user?.firstName ||
      !user?.lastName ||
      !user?.email ||
      !user?.phone
    ) {
      alert("Bitte alle Buchungsdetails ausfüllen.");
      return;
    }

    // ✅ Reset `appointmentDeleted` when making a new booking
    setBookingData((prev) => ({
      ...prev,
      appointmentDeleted: false, // 🔥 Fix: Ensure state doesn't persist incorrectly
    }));

    const safeWaitlistStartTime = waitlistStartTime || null;
    const safeWaitlistEndTime = waitlistEndTime || null;

    const formatDateTime = (date, hour) =>
      `${date}T${String(hour).padStart(2, "0")}:00:00`;

    const calendarid = onWaitlist ? "-1" : "178251";
    const start = onWaitlist
      ? formatDateTime(date, safeWaitlistStartTime)
      : time.start;
    const end = onWaitlist
      ? formatDateTime(date, safeWaitlistEndTime)
      : time.end;

    const idandtimeslot = onWaitlist
      ? `-1|${start.replace("T", " ")}|${end.replace("T", " ")}|Warteliste|0`
      : time.idandtimeslot;

    console.log("📌 Confirming booking with:", {
      service,
      date,
      time,
      user,
      onWaitlist,
      waitlistStartTime,
      waitlistEndTime,
    });

    const payload = new URLSearchParams({
      calendarid,
      services: service.map((s) => s.serviceId).join(","),
      start,
      end,
      idandtimeslot,
      FirstName: user.firstName,
      LastName: user.lastName,
      Email: user.email,
      Phone: user.phone,
      sendemail: 1,
      checkexist: 1,
      appointmentreminderhours: -24,
      appointmentreminderhours2: -23,
    }).toString();

    try {
      const response = await apiRequest(
        "https://www.etermin.net/api/appointment",
        "POST",
        payload
      );
      console.log("✅ API Response:", response);

      // ✅ Parse JSON (Fix)
      const responseData =
        typeof response === "string" ? JSON.parse(response) : response;
      let appointmentId = responseData.ID || null; // ✅ Extract correct ID
      const appointmentIID = responseData.IID || null;

      console.log("📌 Extracted Appointment ID:", appointmentId);

      // ✅ If appointmentId is missing, use Firestore-generated ID
      const userAuth = auth.currentUser;
      if (!appointmentId && userAuth) {
        console.warn(
          "⚠️ Kein Termin ID erhalten. Firestore ID wird verwendet."
        );
        const appointmentsRef = collection(
          db,
          "users",
          userAuth.uid,
          "appointments"
        );
        const newDocRef = await addDoc(appointmentsRef, {
          service,
          date,
          start,
          end,
          user,
          onWaitlist: onWaitlist || false,
          waitlistStartTime: safeWaitlistStartTime,
          waitlistEndTime: safeWaitlistEndTime,
          createdAt: new Date(),
        });
        appointmentId = newDocRef.id;
      }

      if (!appointmentId) {
        throw new Error("❌ Kein Termin ID erhalten. Buchung fehlgeschlagen.");
      }

      // ✅ Store the ID properly
      const updatedBookingData = {
        ...bookingData,
        appointmentId,
        appointmentIID,
        appointmentDeleted: false, // 🔥 Ensure it doesn't persist deleted state
      };
      localStorage.setItem("bookingData", JSON.stringify(updatedBookingData));
      setBookingData(updatedBookingData);
      setAppointmentCreated(true);

      // ✅ Save appointment in Firestore
      if (userAuth) {
        const appointmentRef = doc(
          db,
          "users",
          userAuth.uid,
          "appointments",
          appointmentId
        );
        await setDoc(appointmentRef, {
          appointmentId,
          appointmentIID, 
          service,
          date,
          start,
          end,
          user,
          onWaitlist: onWaitlist || false,
          waitlistStartTime: safeWaitlistStartTime,
          waitlistEndTime: safeWaitlistEndTime,
          createdAt: new Date(),
        });        

        console.log(
          "✅ Appointment stored in Firestore with ID:",
          appointmentId
        );
      }

      navigate("/termin/success");
    } catch (error) {
      console.error("❌ Fehler bei der Terminbuchung:", error);
      alert("Fehler bei der Terminbuchung. Bitte erneut versuchen.");
    }
  };
  // Reset booking data on route change
  useEffect(() => {
    if (!location.pathname.startsWith("/termin")) {
      console.log('trigger')
      setBookingData({
        service: [],
        date: null,
        time: null,
        user: {
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
        },
        appointmentId: null,
        appointmentCreated: false,
        appointmentDeleted: false,
      });
    }
  }, [location.pathname]);

  return isOnline ? (
    <div className="bookings-container" ref={bookingContainerRef}>
      <ProgressBar currentStep={location.pathname} />
      <AnimatePresence>
        <Routes>
          <Route
            path="/"
            element={
              <ServiceSelection
                data={bookingData}
                setData={setBookingData}
                onSelectItems={handleServiceSelection}
                selectedService={bookingData.service} // ✅ Pass selected service
              />
            }
          />
          <Route
            path="/calendar"
            element={
              <CalendarSelection data={bookingData} setData={setBookingData} />
            }
          />
          <Route
            path="/timeslot"
            element={
              <TimeSlotSelection
                data={bookingData}
                setData={setBookingData}
                fetchTimeslots={fetchAvailableTimeslots}
              />
            }
          />
          <Route
            path="/userdata"
            element={
              <UserDataForm data={bookingData} setData={setBookingData} />
            }
          />
          <Route
            path="/confirmation"
            element={
              <Confirmation
                data={bookingData}
                onConfirm={handleConfirmBooking}
              />
            }
          />
          <Route
            path="/success"
            element={
              <BookingSuccess
                data={{ ...bookingData, appointmentCreated }}
                onCancel={() => setAppointmentCreated(false)}
                setBookingData={setBookingData}
              />
            }
          />
        </Routes>
      </AnimatePresence>
    </div>
  ) : (
    <div className="bookings-container">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <h3>Du bist gerade offline!</h3>
        <p>
          Solange du nicht mit dem Internet verbunden bist, kannst du keinen
          Termin buchen.
        </p>
      </motion.div>
    </div>
  );
};

export default Booking;
