import { React } from "react";
import "./myarea.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Grid, Preloader, Modal, MyAreaContent } from "../../components";
import { CompanyName } from "../../data";
import { MyareapageMeta } from "../../data/DataMyareapage";
import { HomepageModal } from "../../data/DataHomepage";

const CompanyNameTitle = CompanyName;
const modal = HomepageModal;
function MyArea() {
  const meta = MyareapageMeta;

  return (
    <div className="content">
      <HelmetProvider>
        <Grid
          grid={{
            className: "page",
            pageName: "myarea-page",
            isFull: false,
          }}
        >
          <Helmet>
            <html lang="de" />
            <title>{CompanyNameTitle} - Mein Bereich</title>
            <meta name="description" content={meta} />
          </Helmet>
          <Modal modal={modal.filter((modal) => modal.section === 1)} />
          <MyAreaContent />
        </Grid>
        <Preloader />
      </HelmetProvider>
    </div>
  );
}

export default MyArea;
