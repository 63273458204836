import { React } from "react";
import "./home.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import {
  Accordion,
  Divider,
  Cta,
  Gallery,
  Grid,
  Highlights,
  Headline,
  Modal,
  Preloader,
  Fullscreensection,
  Parallax,
  FadeText,
} from "../../components";
import { CompanyName } from "../../data";
import {
  HomepageCta,
  HomepageHeadline,
  HomepageHighlights,
  HomepageMeta,
  HomepageModal,
  HomepageSections,
} from "../../data/DataHomepage";
import { GalleryItems, parallaxData } from "../../data/DataDefault";

const CompanyNameTitle = CompanyName;

function Home() {
  const gallery = GalleryItems;
  const cta = HomepageCta;
  const highlights = HomepageHighlights;
  const headline = HomepageHeadline;
  const meta = HomepageMeta;
  const modal = HomepageModal;
  const sections = HomepageSections;
  const parallax = parallaxData;

  return (
    <div className="content">
      <HelmetProvider>
        <Grid
          grid={{
            className: "page",
            pageName: "home-page",
            isFull: true,
          }}
        >
          <Helmet>
            <html lang="de" />
            <title>{CompanyNameTitle} - Home</title>
            <meta name="description" content={meta} />
          </Helmet>
          <Parallax parallax={parallax} />
          <Divider
            divider={{
              dividerHeight: "1rem",
              dividerHeightMobile: "1rem",
              id: 1,
            }}
          />
          <FadeText text="Hier wird Handwerk noch groß geschrieben." />
        </Grid>
        <Grid
          grid={{
            className: "page",
            pageName: "home-page",
            isFull: false,
          }}
        >
          <Divider
            divider={{
              dividerHeight: "0px",
              dividerHeightMobile: "25px",
              id: 1,
            }}
          />
          <Accordion
            accordion={{
              title: "Über XS-Barbershop?",
              content:
                "Seit unserer Gründung im Jahr 2016 haben wir es uns zur Mission gemacht, nicht nur Haare zu schneiden, sondern ein einzigartiges Erlebnis zu schaffen, das in Erinnerung bleibt. Bei uns wird geduzt, denn wir möchten, dass sich jeder Kunde bei uns wie zu Hause fühlt. Wir nehmen uns die Zeit, um deine Wünsche und Bedürfnisse zu verstehen und setzen alles daran, sie zu erfüllen. Hier bekommst du nicht nur einen Haarschnitt, sondern ein ganzheitliches Erlebnis, bei dem du dich entspannen und verwöhnen lassen kannst.",
              id: 1,
              className: "hidden-element",
            }}
          />
          <Accordion
            accordion={{
              title: "Öffnungszeiten",
              content:
                "<strong class='open'>Dienstag:</strong> 9-14 Uhr & 15-20 Uhr <br> <strong>Mittwoch:</strong>  9-14 Uhr & 15-21 Uhr <br><strong>Donnerstag:</strong> 9-14 Uhr & 15-22 Uhr <br><strong>Freitag:</strong> 9-14 Uhr & 15-21 Uhr <br><strong>Samstag:</strong> 9-14 Uhr",
              id: 1,
              className: "hidden-element",
            }}
          />
          <Divider
            divider={{
              dividerHeight: "100px",
              dividerHeightMobile: "50px",
              id: 1,
            }}
          />
          <Headline
            headline={headline.filter((headline) => headline.section === 4)}
          />
          <Divider
            divider={{
              dividerHeight: "50px",
              dividerHeightMobile: "50px",
              id: 1,
            }}
          />
          <Gallery gallery={gallery} />
          <Divider
            divider={{
              dividerHeight: "100px",
              dividerHeightMobile: "100px",
              id: 1,
            }}
          />
          <Headline
            headline={headline.filter((headline) => headline.section === 2)}
          />
          <Divider
            divider={{
              dividerHeight: "34px",
              dividerHeightMobile: "50px",
              id: 1,
            }}
          />
          <Highlights isBookable={true} highlights={highlights} />
          <Divider
            divider={{
              dividerHeight: "200px",
              dividerHeightMobile: "50px",
              id: 1,
            }}
          />
          <Cta cta={cta.filter((cta) => cta.section === 1)} />
          <Divider
            divider={{
              dividerHeight: "200px",
              dividerHeightMobile: "50px",
              id: 1,
            }}
          />
          <Headline
            headline={headline.filter((headline) => headline.section === 3)}
          />
          <Divider
            divider={{
              dividerHeight: "50px",
              dividerHeightMobile: "50px",
              id: 1,
            }}
          />
          <iframe
            className="iframe-xs-barbershop"
            title="XS-Barbershop Innenansicht"
            src="https://www.google.com/maps/embed?pb=!4v1722682681544!6m8!1m7!1sCAoSLEFGMVFpcE1LZjlIdnRRdndZOWdYUW9oc2M5aTZndmRzZDFuS2VBVGdlWi16!2m2!1d49.988955048547!2d8.672197055049!3f56.401040323939185!4f-10.951129893935217!5f0.7820865974627469"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
          <Divider
            divider={{
              dividerHeight: "200px",
              dividerHeightMobile: "100px",
              id: 1,
            }}
          />
          <Cta cta={cta.filter((cta) => cta.section === 2)} />
          <Divider
            divider={{
              dividerHeight: "200px",
              dividerHeightMobile: "100px",
              id: 1,
            }}
          />
          <Modal modal={modal.filter((modal) => modal.section === 1)} />
          <Preloader />
        </Grid>
        <Grid
          grid={{
            className: "page",
            pageName: "home-page",
            isFull: true,
            vh100: false,
          }}
        >
          <Fullscreensection sections={sections} />
        </Grid>
      </HelmetProvider>
    </div>
  );
}

export default Home;
