import React from "react";
import { motion } from "framer-motion";
import { TbRazor } from "react-icons/tb";
import { RiScissorsLine } from "react-icons/ri";
import { FaMask, FaStar } from "react-icons/fa";
import "./booking.css";

const formatDate = (dateString) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  return date.toLocaleDateString("de-DE", {
    weekday: "long",
    day: "2-digit",
    month: "long",
    year: "numeric",
  });
};

const Confirmation = ({ data, onConfirm }) => {
  const { user, service, date, time } = data;

  const getCategoryIcon = (category) => {
    if (!category) return null;
    switch (category) {
      case "VIP":
        return <FaStar />;
      case "Haare":
        return <RiScissorsLine />;
      case "Bart":
        return <TbRazor />;
      case "Kosmetik":
        return <FaMask />;
      default:
        return null;
    }
  };

  return (
    <motion.div className="overview-wrapper">
      <h3>Übersicht</h3>

      <p>
        <strong>Persönliche Daten:</strong>
      </p>
      <p>
        {user.firstName} {user.lastName}
      </p>
      <p>
        <strong>{user.email}</strong>
      </p>
      <p>{user.phone}</p>

      <p>
        <strong>Leistung(en):</strong>
      </p>
      <ul className="list-items success-items">
        {Array.isArray(service) && service.length > 0 && (
          <li className="list-item selected">
            {/* ✅ Group Service Icons & Names */}
            <div className="service-details">
              {service.map((s, index) => (
                <span key={index} className="service-item">
                  <span className="list-item-category">
                    {getCategoryIcon(s.category)}
                  </span>
                  <span className="list-item-title">{s.name}</span>
                </span>
              ))}
            </div>

            {/* ✅ Display Date and Time */}
            <div className="list-item-date">
              {formatDate(date)}{" "}
              {time?.formattedTime || "Keine Uhrzeit angegeben"}
            </div>

            {/* ✅ Calculate and Display Total Price Without ".00" If Whole Number */}
            <div className="list-item-price">
              {(() => {
                const totalPrice = service
                  .map((s) => parseFloat(s.price.replace("€", "").trim())) // ✅ Remove "€" and trim spaces
                  .reduce((total, num) => total + num, 0);

                return Number.isInteger(totalPrice)
                  ? totalPrice
                  : totalPrice.toFixed(2);
              })()}{" "}
              €
            </div>
          </li>
        )}
      </ul>

      <p>
        Mit dem Abschicken des Formulars erklärst du dich mit unserer{" "}
        <a
          className="privacy-link"
          target="_blank"
          rel="noopener noreferrer"
          href="/legal"
        >
          Datenschutzvereinbarung
        </a>{" "}
        einverstanden.
      </p>

      <button
        onClick={onConfirm}
        className="btn-confirm btn-highlight"
        style={{ marginTop: "1rem" }}
      >
        Termin erstellen
      </button>
    </motion.div>
  );
};

export default Confirmation;
