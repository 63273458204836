import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import "./booking.css";

const TimeSlotSelection = ({ data, setData, fetchTimeslots }) => {
  const navigate = useNavigate();
  const [timeslots, setTimeslots] = useState([]);
  const [selectedTimeslot, setSelectedTimeslot] = useState(null);
  const [loading, setLoading] = useState(false);

  // ✅ Format hour (e.g., 9 -> "09:00")
  const formatHour = (hour) => String(hour).padStart(2, "0") + ":00";

  // ✅ Format full datetime (YYYY-MM-DDTHH:mm:ss)
  const formatDateTime = (date, hour) => `${date}T${String(hour).padStart(2, "0")}:00:00`;

  useEffect(() => {
    if (data.onWaitlist) {
      // ✅ Pre-set correctly formatted slot for waitlist
      setSelectedTimeslot({
        start: formatDateTime(data.date, data.waitlistStartTime),
        end: formatDateTime(data.date, data.waitlistEndTime),
        calendarid: "-1", // ✅ Waitlist calendar ID
        calendarname: "Warteliste",
        idandtimeslot: `-1|${formatDateTime(data.date, data.waitlistStartTime).replace("T", " ")}|${formatDateTime(data.date, data.waitlistEndTime).replace("T", " ")}|Warteliste|0`,
        formattedTime: `${formatHour(data.waitlistStartTime)} - ${formatHour(data.waitlistEndTime)}`,
      });
    } else if (data.service.length > 0 && data.date) {
      setLoading(true);
      fetchTimeslots(data.service, data.date).then((slots) => {
        setTimeslots(slots);
        setLoading(false);
      });
    }
  }, [data.service, data.date, fetchTimeslots, data.onWaitlist, data.waitlistStartTime, data.waitlistEndTime]);

  const handleTimeslotSelect = (slot) => {
    setSelectedTimeslot(slot);
  };

  const handleManualTimeChange = (field, value) => {
    setSelectedTimeslot((prev) => ({
      ...prev,
      [field]: formatDateTime(data.date, parseInt(value.split(":")[0])), // Convert input time to full format
    }));
  };

  const confirmTimeslot = () => {
    setData({ ...data, time: selectedTimeslot });
    navigate("/termin/userdata");
  };

  return (
    <motion.div initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -50 }} key="timeslots-wrapper">
      <div className="timeslots-wrapper">
        <h3>Zeit auswählen</h3>

        {/* ✅ If on waitlist, show manual input with limits */}
        {data.onWaitlist ? (
          <div className="waitlist-time-input">
            <label>Startzeit:</label>
            <input
              type="time"
              value={selectedTimeslot?.start?.split("T")[1].slice(0, 5) || ""}
              onChange={(e) => handleManualTimeChange("start", e.target.value)}
              min={formatHour(data.waitlistStartTime)}
              max={formatHour(data.waitlistEndTime)}
            />
            <label>Endzeit:</label>
            <input
              type="time"
              value={selectedTimeslot?.end?.split("T")[1].slice(0, 5) || ""}
              onChange={(e) => handleManualTimeChange("end", e.target.value)}
              min={formatHour(data.waitlistStartTime)}
              max={formatHour(data.waitlistEndTime)}
            />
          </div>
        ) : (
          <ul className="list-items">
            {loading ? (
              <li className="list-item">Lade verfügbare Zeiten...</li>
            ) : timeslots.length > 0 ? (
              timeslots.map((slot) => (
                <li
                  className={`list-item ${selectedTimeslot?.idandtimeslot === slot.idandtimeslot ? "selected" : ""}`}
                  key={slot.idandtimeslot}
                  onClick={() => handleTimeslotSelect(slot)}
                >
                  {slot.formattedTime}
                </li>
              ))
            ) : (
              <li className="list-item">Keine verfügbaren Termine</li>
            )}
          </ul>
        )}

        <button className="btn-confirm btn-highlight" onClick={confirmTimeslot} disabled={!selectedTimeslot}>
          Zeit bestätigen
        </button>
      </div>
    </motion.div>
  );
};

export default TimeSlotSelection;
