import React, { useState, useEffect } from "react";
const setDark = () => {
  localStorage.setItem("theme", "dark");
  document.documentElement.setAttribute("data-theme", "dark");
};
const storedTheme = localStorage.getItem("theme");

const prefersDark =
  window.matchMedia &&
  window.matchMedia("(prefers-color-scheme: dark)").matches;

const defaultDark =
  storedTheme === "dark" || (storedTheme === null && prefersDark);

if (defaultDark) {
  setDark();
}

function DarkMode({ darkmodeActive }) {
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
  window.addEventListener("custom-colors", (e) => {
    if (localStorage.getItem("theme" === "light")) {
      setTheme("light");
    localStorage.setItem("theme", "light")
    }
    else if (localStorage.getItem("theme" === "dark")) {
      setTheme("dark");
      localStorage.setItem("theme", "dark")
    }
  });
  const toggleTheme = () => {
    if (theme === "light") {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  };
  useEffect(() => {
    if (theme === "light") {
      localStorage.setItem("theme", "light");
      document.documentElement.removeAttribute("data-theme");
    }
    if (theme === "dark") {
      localStorage.setItem("theme", "dark");
      document.documentElement.setAttribute("data-theme", "dark");
    }
    if (defaultDark) {
      setDark(true);
    }
    localStorage.setItem("theme", theme);
    document.documentElement.setAttribute("data-theme", theme);
  }, [theme]);

  if (darkmodeActive)
    return (
      <button onClick={toggleTheme} className="btn-outline">
        {theme === "light" ? "LIGHTMODE ☀️" : "DARKMODE🌒"}
      </button>
    );
  else
    return (
      <button id="toggle-btn" className="btn-outline" onClick={toggleTheme}>
        {theme === "light" ? "LIGHTMODE ☀️" : "DARKMODE🌒 🌒"}
      </button>
    );
}
export default DarkMode;
