import React, { useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import apiRequest from "../../auth/auth";
import Confetti from "../confetti/Confetti";
import { doc, deleteDoc } from "firebase/firestore";
import { auth, db } from "../../firebaseConfig"; // ✅ Firebase Config
import { TbRazor } from "react-icons/tb";
import { RiScissorsLine } from "react-icons/ri";
import { FaMask, FaStar } from "react-icons/fa";
import "./booking.css";

const BookingSuccess = ({ data = {}, setBookingData }) => {
  const navigate = useNavigate();
  const [isDeleting, setIsDeleting] = useState(false);
  const {
    user = {},
    service = [],
    date,
    time,
    appointmentCreated,
    appointmentDeleted,
    appointmentId,
  } = data;

  // ✅ Format Date to Readable String
  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    return date.toLocaleDateString("de-DE", {
      weekday: "long",
      day: "2-digit",
      month: "long",
      year: "numeric",
    });
  };

  // ✅ Delete Appointment Function (Only Uses `bookingData`)

  const deleteAppointment = async (appointmentId, setBookingData) => {
    if (isDeleting) return; // Prevent multiple clicks
    setIsDeleting(true);

    if (!appointmentId || appointmentId === "N/A") {
      alert("❌ Fehler: Kein gültiger Termin gefunden.");
      setIsDeleting(false);
      return;
    }

    // ✅ Confirm deletion
    if (
      !window.confirm(
        "Möchtest du diesen Termin wirklich stornieren? Diese Aktion kann nicht rückgängig gemacht werden."
      )
    ) {
      setIsDeleting(false);
      return;
    }

    const payload = new URLSearchParams({
      externalid: appointmentId,
      sendemail: "1",
      sendcopy: "1",
      msgtype: "11",
      sync: "1"
    }).toString();

    const headers = { "Content-Type": "text/plain" };

    try {
      const response = await apiRequest(
        "https://www.etermin.net/api/appointment",
        "DELETE",
        payload,
        headers
      );
      // ✅ Parse XML Response
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(response, "application/xml");
      const status = xmlDoc.getElementsByTagName("status")[0]?.textContent;
      const statusMsg =
        xmlDoc.getElementsByTagName("statusmsg")[0]?.textContent;

      if (status === "200") {
        console.log("✅ Termin erfolgreich gelöscht:", statusMsg);

        // ✅ Remove appointment from Firestore (if user is logged in)
        if (auth.currentUser?.uid) {
          const userRef = doc(
            db,
            "users",
            auth.currentUser.uid,
            "appointments",
            appointmentId
          );

          if (appointmentId.trim() !== "" && appointmentId !== "N/A") {
            await deleteDoc(userRef);
            console.log(`🔥 Termin ${appointmentId} aus Firestore gelöscht.`);
          } else {
            console.warn(
              "⚠️ Ungültige appointmentId, Firestore-Delete übersprungen."
            );
          }
        }

        // ✅ Update `setBookingData` to reflect the deletion
        setBookingData((prev) => ({
          ...prev,
          service: [],
          date: null,
          time: null,
          appointmentId: null, // ✅ Reset appointmentId
          appointmentCreated: false,
          appointmentDeleted: true, // ✅ Show deleted screen
        }));

        alert("✅ Termin wurde erfolgreich storniert.");
      } else {
        console.error("❌ Fehler beim Löschen des Termins:", status, statusMsg);
        alert("Fehler beim Löschen des Termins.");
      }
    } catch (error) {
      console.error("❌ Fehler beim Löschen des Termins:", error);
      alert("Fehler beim Löschen des Termins.");
    }
  };

  // ✅ Reset Booking Data and Redirect
  const resetAndBookNew = () => {
    setBookingData((prev) => ({
      ...prev,
      service: [],
      date: null,
      time: null,
      appointmentId: null,
      appointmentCreated: false, // ✅ Reset created flag
      appointmentDeleted: false, // ✅ Reset deleted flag
      onWaitlist: false,
      waitlistStartTime: null,
      waitlistEndTime: null,
    }));
    navigate("/termin"); // ✅ Redirect to booking start
  };

  // ✅ Reset Booking Data and Redirect
  const resetAndHome = () => {
    setBookingData((prev) => ({
      ...prev,
      service: [],
      date: null,
      time: null,
      appointmentId: null,
      appointmentCreated: false, // ✅ Reset created flag
      appointmentDeleted: false, // ✅ Reset deleted flag
      onWaitlist: false,
      waitlistStartTime: null,
      waitlistEndTime: null,
    }));
    navigate("/"); // ✅ Redirect to home
  };

  const getCategoryIcon = (category) => {
    if (!category) return null;
    switch (category) {
      case "VIP":
        return <FaStar />;
      case "Haare":
        return <RiScissorsLine />;
      case "Bart":
        return <TbRazor />;
      case "Kosmetik":
        return <FaMask />;
      default:
        return null;
    }
  };

  const downloadICS = (appointment) => {
    if (!appointment.start || !appointment.end) return;

    const timezone = "Europe/Berlin";

    // ✅ Get service names (comma-separated)
    const serviceNames =
      appointment.service
        ?.map((s) => s.name)
        .filter(Boolean)
        .join(", ") || "Termin";

    // ✅ Define website URL
    const websiteURL = "https://xs-barbershop.com";

    // ✅ Fix location display by replacing commas with " - "
    const location = "XS-Barbershop - Fabrikstraße 32 - 63225 Langen";

    // ✅ Use Apple's structured location with coordinates (geo:lat,long)
    const appleLocation = "geo:50.003,8.674"; // Replace with actual lat,long if needed

    // ✅ Build the detailed description with location + website
    const description = `Du erhältst: ${serviceNames}\n\nDu willst deinen Termin stornieren? Besuche unsere Website:\n${websiteURL}\n\nOrt: ${location}`;

    // ✅ Dynamic ICS content with fixed LOCATION
    const icsContent =
      "BEGIN:VCALENDAR\r\n" +
      "VERSION:2.0\r\n" +
      "CALSCALE:GREGORIAN\r\n" +
      "PRODID:-//XS-Barbershop//NONSGML v1.0//EN\r\n" +
      "BEGIN:VEVENT\r\n" +
      "UID:" +
      appointment.id +
      "\r\n" +
      "DTSTAMP:" +
      new Date().toISOString().replace(/[-:]/g, "").split(".")[0] +
      "Z\r\n" + // Keep in UTC for timestamp
      "DTSTART;TZID=" +
      timezone +
      ":" +
      appointment.start +
      "\r\n" + // ✅ Local time with timezone
      "DTEND;TZID=" +
      timezone +
      ":" +
      appointment.end +
      "\r\n" + // ✅ Local time with timezone
      "SUMMARY:" +
      serviceNames +
      " im XS-Barbershop\r\n" +
      "DESCRIPTION:" +
      description +
      "\r\n" +
      "LOCATION:" +
      location +
      "\r\n" +
      "URL:" +
      websiteURL +
      "\r\n" +
      "X-APPLE-STRUCTURED-LOCATION;VALUE=URI;X-TITLE=XS-Barbershop:" +
      appleLocation +
      "\r\n" +
      "BEGIN:VALARM\r\n" +
      "ACTION:DISPLAY\r\n" +
      "DESCRIPTION:Erinnerung an deinen Termin (" +
      serviceNames +
      ")\r\n" +
      "TRIGGER;RELATED=START:-PT24H\r\n" +
      "END:VALARM\r\n" +
      "END:VEVENT\r\n" +
      "END:VCALENDAR\r\n";

    // ✅ Generate a clean filename with no underscores, only commas
    const cleanFilename = `Termin-${
      serviceNames.replace(/_/g, "").replace(/\s+/g, " ") || "Event"
    }.ics`;

    // ✅ Create and download the ICS file
    const blob = new Blob([icsContent], {
      type: "text/calendar;charset=utf-8",
    });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = cleanFilename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <motion.div className="overview-wrapper">
      {/* ✅ Show Confirmation If Appointment Created */}
      {appointmentCreated && !appointmentDeleted && (
        <>
          <h3>Termin erfolgreich erstellt</h3>
          <p>
            Du erhältst in Kürze eine Bestätigung inklusive Kalenderdatei per
            Mail an folgende Adresse:
          </p>
          <p>
            <strong>{user.email || "Keine E-Mail angegeben"}</strong>
          </p>

          <p>
            <strong>Leistung(en):</strong>
          </p>
          <ul className="list-items success-items">
            {Array.isArray(service) && service.length > 0 && (
              <li className="list-item selected">
                {/* ✅ Group Service Icons & Names */}
                <div className="service-details">
                  {service.map((s, index) => (
                    <span key={index} className="service-item">
                      <span className="list-item-category">
                        {getCategoryIcon(s.category)}
                      </span>
                      <span className="list-item-title">{s.name}</span>
                    </span>
                  ))}
                </div>

                {/* ✅ Display Date and Time */}
                <div className="list-item-date">
                  {formatDate(date)}{" "}
                  {time?.formattedTime || "Keine Uhrzeit angegeben"}
                </div>

                {/* ✅ Calculate and Display Total Price Without ".00" If Whole Number */}
                <div className="list-item-price">
                  {(() => {
                    const totalPrice = service
                      .map((s) => parseFloat(s.price.replace("€", "").trim())) // ✅ Remove "€" and trim spaces
                      .reduce((total, num) => total + num, 0);

                    return Number.isInteger(totalPrice)
                      ? totalPrice
                      : totalPrice.toFixed(2);
                  })()}{" "}
                  €
                </div>
              </li>
            )}
          </ul>
          <button
            className="btn-outline"
            onClick={() => {
              if (!data?.date || !data?.time?.start || !data?.time?.end) {
                alert("❌ Fehler: Ungültiges Datum oder Uhrzeit.");
                return;
              }

              // ✅ Manually create the datetime strings to avoid UTC conversion
              const formatLocalDateTime = (dateStr) => {
                const date = new Date(dateStr);
                return (
                  date.getFullYear() +
                  ("0" + (date.getMonth() + 1)).slice(-2) + // Month (zero-padded)
                  ("0" + date.getDate()).slice(-2) +
                  "T" + // Day (zero-padded)
                  ("0" + date.getHours()).slice(-2) + // Hours (zero-padded)
                  ("0" + date.getMinutes()).slice(-2) + // Minutes (zero-padded)
                  ("0" + date.getSeconds()).slice(-2)
                ); // Seconds (zero-padded)
              };

              // ✅ Ensure valid start & end times
              const startTime = formatLocalDateTime(data.time.start);
              const endTime = formatLocalDateTime(data.time.end);

              downloadICS({
                id: data.appointmentId,
                start: startTime, // ✅ Manually formatted local time
                end: endTime, // ✅ Prevents UTC shift
                service: data.service,
              });
            }}
          >
            Termin zum Kalender hinzufügen
          </button>
          <button className="btn-outline back-to-home" onClick={resetAndHome}>
            Zurück zur Homepage
          </button>
          <button
            className="btn-highlight back-to-home"
            onClick={resetAndBookNew}
          >
            Neuen Termin buchen
          </button>
          <p className="delete-appointment">
            <strong>Du hast einen Fehler gemacht?</strong>
          </p>
          <button
            className="btn-outline"
            disabled={isDeleting}
            onClick={() => deleteAppointment(appointmentId, setBookingData)}
          >
            {isDeleting ? "Lösche Termin..." : "Termin stornieren"}
          </button>

          <Confetti />
        </>
      )}

      {/* ✅ Show If Appointment Was Deleted */}
      {appointmentDeleted && (
        <>
          <h3>Termin erfolgreich storniert</h3>
          <p>
            Du erhältst in Kürze eine Bestätigung per Mail an folgende Adresse:
          </p>
          <p>
            <strong>{user.email || "Keine E-Mail angegeben"}</strong>
          </p>

          <div style={{ height: "90px" }}></div>
          <button
            className="btn-highlight back-to-home"
            onClick={resetAndBookNew}
          >
            Neuen Termin buchen
          </button>
          <button className="btn-outline back-to-home" onClick={resetAndHome}>
            Zurück zur Homepage
          </button>
        </>
      )}
    </motion.div>
  );
};

export default BookingSuccess;
