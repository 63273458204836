import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { ToTopButtonItemsDefault } from "../../data/DataDefault";
import "./totopbutton.css";
import ToTopButtonItems from "./ToTopButtonItems";
import { AnimatePresence, easeInOut, motion } from "framer-motion";

const ToTopButton = () => {
  const [visible, setVisible] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const toTopRef = useRef();
  const totopbuttonitems = ToTopButtonItemsDefault;
  const location = useLocation();
  const pathname = location.pathname;

  const handleOutsideClick = (e) => {
    if (toTopRef.current && !toTopRef.current.contains(e.target)) {
      setShowButtons(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
      setShowButtons(false);
    }
  };

  const toggleButton = () => {
    setShowButtons(!showButtons);
    if (!showButtons && pathname === "/") {
      setTimeout(setHomeOpacity, 5);
    }
  };

  function setHomeOpacity() {
    const home = document.getElementsByClassName("to-top-button-items");
    if (home.length > 0) {
      home[0].classList.add("greyed-out");
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", toggleVisible);
    return () => window.removeEventListener("scroll", toggleVisible);
  }, []);

  return (
    <>
      <div
        ref={toTopRef}
        className={!showButtons ? "to-top-button initial" : "to-top-button"}
      >
        <AnimatePresence>
          {visible && (
            <motion.button
              key="to-top-button"
              onClick={toggleButton}
              className="btn btn-dark btn-to-top"
              animate={{
                y: [-100, 0, 100, 0, -100, 0],
                scale: [1, 0.25, 0.5, 0.75, 1],
              }}
              transition={{
                repeat: 2,
                duration: 0.5,
                delay: 0,
                ease: easeInOut,
              }}
            >
              <i
                className={showButtons ? "fas fa-times" : "fas fa-ellipsis-h"}
              ></i>
            </motion.button>
          )}
          {showButtons && (
            <motion.div
              key="to-top-button-items"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <ToTopButtonItems totopbuttonitems={totopbuttonitems} />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  );
};

export default ToTopButton;
