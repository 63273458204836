import { React } from "react";
import "./login.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Grid, Preloader, Modal } from "../../components";
import { CompanyName } from "../../data";
import {
  LoginpageMeta,
} from "../../data/DataLoginpage";
import { HomepageModal } from "../../data/DataHomepage";
import LoginComponent from "../../components/login/LoginComponent";

const CompanyNameTitle = CompanyName;
const modal = HomepageModal;
function Login() {
  const meta = LoginpageMeta;

  return (
    <div className="content">
      <HelmetProvider>
          <Grid
            grid={{
              className: "page",
              pageName: "login-page",
              isFull: false,
            }}
          >
            <Helmet>
              <html lang="de" />
              <title>{CompanyNameTitle} - Login</title>
              <meta name="description" content={meta} />
            </Helmet>
            <LoginComponent />
            <Modal modal={modal.filter((modal) => modal.section === 1)} />
          </Grid>
          <Preloader />
        </HelmetProvider>
    </div>
  );
}

export default Login;
