import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import "./booking.css";
import Calendar from "./Calendar";

const CalendarSelection = ({ data, setData }) => {
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(data.date || "");

  const selectedServiceId = data?.service?.map((s) => s.serviceId) || [];
  console.log("📌 Debugging CalendarSelection - serviceIds:", selectedServiceId);

  const handleDateChange = (date, waitlistStart, waitlistEnd) => {
    setSelectedDate(date);

    if (waitlistStart && waitlistEnd) {
      setData((prev) => ({
        ...prev,
        date,
        onWaitlist: true,
        waitlistStartTime: waitlistStart,
        waitlistEndTime: waitlistEnd,
      }));
    } else {
      setData((prev) => ({
        ...prev,
        date,
        onWaitlist: false,
      }));
    }
  };

  // ✅ Format date to "dd.mm.yyyy"
  const formatDate = (date) => {
    if (!date) return "";
    const d = new Date(date);
    return d.toLocaleDateString("de-DE", {
      weekday: "long", // "Mittwoch"
      day: "numeric", // "1"
      month: "long", // "Januar"
      year: "numeric", // "2025"
    });
  };

  return (
    <AnimatePresence>
      <motion.div initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -50 }}>
        <div className="date-picker-wrapper">
          <h3>Tag auswählen</h3>
          {/* ✅ Pass serviceId & date handler to Calendar */}
          <Calendar serviceId={selectedServiceId} onSelectDate={handleDateChange} />
          {/* ✅ Proper placeholder and date formatting */}
          <input
            type="text"
            id="booking-date"
            className="date-picker"
            value={selectedDate ? formatDate(selectedDate) : ""}
            readOnly
            placeholder="Bitte wähle ein Datum aus"
          />
          
          {/* ✅ Show message if on waitlist */}
          {data.onWaitlist && (
            <p className="waitlist-info">
              <strong>Hinweis:</strong> Für dieses Datum gibt es keine freien Termine. Du wirst auf die Warteliste gesetzt.
            </p>
          )}

          <button
            className="btn-confirm btn-highlight"
            onClick={() => {
              setData({ ...data, date: selectedDate });
              navigate("/termin/timeslot");
            }}
            disabled={!selectedDate}
          >
            Datum bestätigen
          </button>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default CalendarSelection;
