export const AboutpageBox = [
  { 
    title: 'Addresse', 
    description: 'XS-Barbershop GmbH', 
    secondDescription: 'Fabrikstraße 32', 
    thirdDescription: '63225 Langen', 
    className: 'box half box-dark', 
    headlineClassname: 'box-headline headline-bright', 
    textClassname: 'box-text text-bright',  
    buttonText: '', 
    buttonClassname: 'btn-dark', 
    imgClassName: 'box-img box-img-rounded', 
    boxIcon: 'fas fa-map',
    id: 8, 
    section: 1 
  },
  { 
    title: 'Kontakt', 
    description: '', 
    secondDescription: '', 
    linkName: '06103 4877822', 
    linkTarget:'tel:061034877822', 
    linkNameIcon:'fa fa-phone', 
    secondLinkName: 'info@xs-barbershop.com', 
    secondLinkTarget:'mailto:info@xs-barbershop.com', 
    secondLinkNameIcon:'fa fa-envelope', 
    thirdLinkName: 'Maps', 
    thirdLinkTarget:'https://maps.app.goo.gl/oge8Ve4fdU3xjiyz6', 
    thirdLinkNameIcon:'fas fa-compass', 
    className: 'box half box-dark', 
    headlineClassname: 'box-headline headline-bright', 
    textClassname: 'box-text text-bright', 
    buttonText: '', 
    buttonClassname: 'btn-dark', 
    imgClassName: 'box-img box-img-rounded',
    boxIcon: 'fas fa-address-card', 
    id: 7, 
    section: 1 
  },
]

export const AboutpageHero = [
  { 
    title: 'Über Uns', 
    description: 'Erfahre mehr über uns! Auf dieser Seite teilen wir Einblicke in die Entstehung und Entwicklung unseres Barbershops. ', 
    className: 'hero', 
    containerClassName: 'hero-container', 
    buttonClass: 'btn-dark', 
    buttonText: '', 
    buttonLink: '#', 
    imgClassName: '', 
    backgroundImage: require('../images/hero-bg.jpg'),
    cssid: 'hero-home', 
    id: 1 },

]

export const AboutpageCta = [
  {
    headline: 'Preisliste / Übersicht Leistungen',
    description: 'Ob Haarschnitt oder Bartpflege – wir bieten Qualität zu fairen Konditionen. Informiere dich über unsere Leistungen und finde heraus, wie wir deinen Stil perfektionieren können. Plane deinen nächsten Besuch im XS-Barbershop mit einem Blick auf unsere Angebote.',
    className: 'cta full',
    containerClassName: 'cta-container default-shadow',
    buttonClass: 'btn-highlight',
    buttonText: 'Leistungen und Preise',
    buttonLink: '/services',
    imgClassName: '',
    id: 1,
    section: 1,
  },
]

export const AboutpageHighlights = [
  { 
    title: 'Headline of Highlights Element', 
    icon: 'fas fa-file', 
    className: 'highlight', 
    containerClassName: 'highlight-container', 
    id: 1 
  },
  { 
    title: 'Headline of Highlights Element', 
    icon: 'fas fa-file', 
    className: 'highlight', 
    containerClassName: 'highlight-container', 
    id: 2 
  },
  { 
    title: 'Headline of Highlights Element', 
    icon: 'fas fa-file', 
    className: 'highlight', 
    containerClassName: 'highlight-container', 
    id: 3
  },
  { 
    title: 'Headline of Highlights Element', 
    icon: 'fas fa-file', 
    className: 'highlight', 
    containerClassName: 'highlight-container', 
    id: 4
  },
]

export const AboutpageHeadline = [
  { 
    className: 'headline headline-center', 
    containerClassName: 'headline-container', 
    headline: 'Meilensteine', 
    id: 1, 
    section: 1 
  },
]

export const AboutpageUsercard = [
  { 
    title: 'Über Daniel Büttner', 
    icon: 'fas fa-arrow-circle-right', 
    description: 'Ich bin Daniel Büttner, seit über 10 Jahren Barbier aus Leidenschaft. Meine Ausbildung absolvierte ich in einem renommierten Fachbetrieb in Langen. Jeden Tag freue ich mich darauf, meine Kunden glücklich zu machen und ihnen mit präziser Handwerkskunst und individueller Betreuung ein Lächeln ins Gesicht zu zaubern.', 
    subtext:'Inhaber und Geschäftsführer', 
    dataDate:'2021',
    image: require("../images/daniel_about.jpg"),
    id: 1, 
    section: 1  
  },
]

export const AboutpageModal = [
  { 
    className: 'modal modal-center', 
    containerClassName: 'modal-container', 
    title: 'Welcome on my website!', 
    text: 'Nice to have you here. Please provide some information about yourself!', 
    textTwo: 'If you do not want to provide personal information, simply close this dialog. You can edit your settings at any time by clicking the edit button on top of this page.', 
    button: 'Enter your name', 
    id: 1, 
    section: 1 
  },
]

export const AboutpageTimeline = [
  { 
    title: 'Umfirmierung XS-Barbershop GmbH', 
    icon: 'fas fa-arrow-circle-right', 
    description: 'Aufgrund des wachsenden Kundenzustroms habe ich mein Einzelunternehmen in eine GmbH umfirmiert. Diese Veränderung ermöglicht es uns, professioneller und effizienter zu arbeiten und unseren Service weiter zu verbessern.', 
    subtext:'2020', 
    dataDate:'2021', 
    id: 1, 
    section: 1  
  },
  { 
    title: 'Gründung XS-Barbershop UG', 
    icon: 'fas fa-arrow-circle-right',
    description: 'Die Gründung meines ersten Barbershops, XS-Barbershop, war ein großer Meilenstein. Mit Leidenschaft und Engagement habe ich einen Ort geschaffen, an dem individuelle Haarschnitte und Bartpflege im Mittelpunkt stehen.', 
    subtext:'2016', 
    dataDate:'2020', 
    id: 2, 
    section: 1 
  },
  { 
    title: 'Ausbildung', 
    icon: 'fas fa-arrow-circle-right',
    description: 'Meine erfolgreiche Ausbildung in einem ausgezeichneten Friseursalon in Langen hat mir das nötige Fachwissen und die Fähigkeiten vermittelt. So kann ich dir immer erstklassige Haarschnitte und individuelle Bartpflege anbieten.', 
    subtext:'2012', 
    dataDate:'2022', 
    id: 3, 
    section: 1  
  },
  { 
    title: 'Fort- & Weiterbildungen', 
    icon: 'fas fa-arrow-circle-right', 
    description: 'Durch regelmäßige Fortbildungen halten wir uns stets auf dem neuesten Stand, was Werkzeuge und moderne Techniken für Haarschnitte und Bartpflege betrifft. So können wir dir jederzeit die besten Ergebnisse bieten.', 
    subtext:'', 
    dataDate:'2021', 
    id: 4, 
    section: 1 
  },
]
export const AboutpageMeta = ''
