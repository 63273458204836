import { React } from "react";
import "./about.css";
import {
  Preloader,
  Divider,
  Grid,
  Hero,
  Modal,
  Fullscreensection,
  Timeline,
  Headline,
  Cta,
  Usercard,
} from "../../components";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { CompanyName } from "../../data";
import {
  AboutpageHeadline,
  AboutpageHero,
  AboutpageMeta,
  AboutpageTimeline,
  AboutpageCta,
  AboutpageUsercard,
} from "../../data/DataAboutpage";
import { HomepageModal, HomepageSections } from "../../data/DataHomepage";

const CompanyNameTitle = CompanyName;

function About() {
  const headline = AboutpageHeadline;
  const hero = AboutpageHero;
  const meta = AboutpageMeta;
  const modal = HomepageModal;
  const timeline = AboutpageTimeline;
  const cta = AboutpageCta;
  const usercard = AboutpageUsercard;
  const sections = HomepageSections;

  return (
    <div className="content">
      <HelmetProvider>
        <Grid
          grid={{
            className: "page",
            pageName: "about-page",
            isFull: true,
          }}
        >
          <Helmet>
            <html lang="de" />
            <title>{CompanyNameTitle} - Über Uns</title>
            <meta name="description" content={meta} />
          </Helmet>
          <Hero hero={hero} />
        </Grid>
        <Grid
          grid={{
            className: "page",
            pageName: "about-page",
            isFull: false,
          }}
        >
          <Divider
            divider={{
              dividerHeight: "50px",
              dividerHeightMobile: "25px",
              id: 1,
            }}
          />
          <Usercard usercard={usercard} />
          <Divider
            divider={{
              dividerHeight: "100px",
              dividerHeightMobile: "100px",
              id: 1,
            }}
          />
          <Headline
            headline={headline.filter((headline) => headline.section === 1)}
          />
          <Divider
            divider={{
              dividerHeight: "50px",
              dividerHeightMobile: "50px",
              id: 1,
            }}
          />
          <Timeline
            timeline={timeline.filter((timeline) => timeline.section === 1)}
          />
          <Divider
            divider={{
              dividerHeight: "200px",
              dividerHeightMobile: "100px",
              id: 1,
            }}
          />
          <Cta cta={cta.filter((cta) => cta.section === 1)} />
          <Divider
            divider={{
              dividerHeight: "200px",
              dividerHeightMobile: "100px",
              id: 1,
            }}
          />
          <Modal modal={modal.filter((modal) => modal.section === 1)} />
        </Grid>
        <Grid
          grid={{
            className: "page",
            pageName: "home-page",
            isFull: true,
            vh100: false,
          }}
        >
          <Fullscreensection sections={sections} />
        </Grid>
        <Preloader />
      </HelmetProvider>
    </div>
  );
}

export default About;
